import { FindLocations_locations } from 'operations/FindLocations'
import type { GetProfileByOwnerId_profiles } from 'operations/GetProfileByOwnerId'
import { GetReferrals_opportunities } from 'operations/GetReferrals'
import { InsertOneReferral } from 'operations/InsertOneReferral'

import {
  M_INSERT_REFERRAL,
  M_UPDATE_REFERRAL,
} from 'lib/mutations/opportunities'
import { Q_GET_REFERRAL, Q_GET_REFERRALS } from 'lib/queries/opportunities'

import { useMutation } from '@apollo/client'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import * as linkify from 'linkifyjs'
import { isEmpty, isNil, prop } from 'ramda'
import {
  Dispatch,
  Fragment,
  FunctionComponent,
  SetStateAction,
  useMemo,
  useState,
} from 'react'
import { whether } from 'utils'

import CustomButton from 'components/common/custom_button'
import useLinkPreview from 'components/hooks/usePreviewLink'
import LinkPreview from 'components/link_preview'

import ReferralPatientInfo from './referral_patient_info'

interface ReferralEditModalProps {
  isEditingReferral: boolean
  setIsEditingReferral: Dispatch<SetStateAction<boolean>>
  data: GetReferrals_opportunities | undefined
  ownerProfile: GetProfileByOwnerId_profiles | undefined
  isNewReferral?: boolean
}

const ReferralFormModal: FunctionComponent<ReferralEditModalProps> = ({
  isEditingReferral,
  setIsEditingReferral,
  data,
  ownerProfile,
  isNewReferral = false,
}) => {
  enum Category {
    Referral = 'Referral',
  }

  const referralTitlePlaceholder = 'ex: 12yo with anxiety, Richmond, VA'

  const referralContentPlaceholder =
    'ex: 12yo boy dealing with anxiety since pandemic. Parents prefer in-person but open to teletherapy. Aetna insurance but can pay out-of-pocket.'

  const [referralTitle, setReferralTitle] = useState(data?.title)

  const [referralContent, setReferralContent] = useState(data?.content)
  const [isEditingReferralTitle, setIsEditingReferralTitle] = useState(false)

  //linkpreview
  const linkPreviewUrl = useMemo(() => {
    const results = linkify.find(referralContent || '')
    return whether(!isNil(results) && results.length > 0, results[0]?.href)
  }, [referralContent])

  const linkPreview = useLinkPreview(linkPreviewUrl)

  //patient_info
  const [patientType, setPatientType] = useState(data?.seeking_info.type)
  const [patientGender, setPatientGender] = useState(data?.seeking_info.gender)
  const [patientInsurance, setPatientInsurance] = useState(
    data?.seeking_info.insurance,
  )

  const [patientModality, setPatientModality] = useState(
    data?.seeking_info.modality,
  )
  const [patientLocation, setPatientLocation] = useState<
    FindLocations_locations | null | undefined
  >(data?.seeking_info.location)

  const maxTitleLength = 80
  const titleLength = prop('length', referralTitle || '')

  const whipeFields = () => {
    setReferralTitle('')
    setReferralContent('')
    setPatientGender('')
    setPatientType('')
    setPatientInsurance('')
    setPatientModality('')
    setPatientLocation(null)
  }

  const closeModal = () => {
    setIsEditingReferral(false)
    setRequiredFieldsNotSubmitted(false)
    if (isNewReferral) {
      whipeFields()
    }
  }

  const [requiredFieldsNotSubmitted, setRequiredFieldsNotSubmitted] =
    useState(false)
  const requiredFieldsFilled = !isEmpty(referralTitle) && patientLocation

  const [updateReferral] = useMutation(M_UPDATE_REFERRAL, {
    refetchQueries: [
      { query: Q_GET_REFERRALS },
      'GetReferrals',
      { query: Q_GET_REFERRAL },
      'GetReferralById',
    ],
  })

  const handleUpdateReferral = () => {
    if (requiredFieldsFilled) {
      updateReferral({
        variables: {
          author_profile_id: ownerProfile?.id || -1,
          id: data?.id,
          title: referralTitle,
          content: referralContent,
          seeking_info: {
            type: patientType,
            gender: patientGender,
            location: patientLocation,
            insurance: patientInsurance,
            modality: patientModality,
          },
          link_preview: linkPreview,
          updated_at: new Date(),
        },
      })
      setIsEditingReferral(false)
    } else {
      setRequiredFieldsNotSubmitted(true)
    }
  }

  const [insertNewReferral] = useMutation<InsertOneReferral>(
    M_INSERT_REFERRAL,
    {
      refetchQueries: [{ query: Q_GET_REFERRALS }, 'GetReferrals'],
    },
  )

  const insertReferral = () => {
    if (requiredFieldsFilled) {
      insertNewReferral({
        variables: {
          author_profile_id: ownerProfile?.id,
          category: Category.Referral,
          title: referralTitle,
          content: referralContent,
          seeking_info: {
            type: patientType,
            gender: patientGender,
            location: patientLocation,
            insurance: patientInsurance,
            modality: patientModality,
          },
          link_preview: linkPreview,
        },
      })
      setIsEditingReferral(false)
    } else {
      setRequiredFieldsNotSubmitted(true)
    }
  }

  const isTitleEmpty =
    requiredFieldsNotSubmitted &&
    (isNil(referralTitle) || isEmpty(referralTitle))

  const navbarHeight = JSON.parse(
    window?.localStorage?.getItem('navbarHeight') || '',
  )

  return (
    <div>
      <Transition appear show={isEditingReferral} as={Fragment}>
        <Dialog as="div" onClose={closeModal} className="relative z-10">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0">
            <div className="flex min-h-full text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 -translate-x-full"
                enterTo="opacity-100 translate-x-0"
                leave="ease-in duration-300"
                leaveFrom="opacity-00 translate-x-0"
                leaveTo="opacity-0 -translate-x-full"
              >
                <Dialog.Panel
                  className={clsx(
                    'overflow-y-auto fixed left-0 py-2 min-w-min max-w-7xl h-screen text-left bg-white shadow-xl transition-all transform',
                  )}
                  style={{ top: `${navbarHeight}px` }}
                >
                  <Dialog.Title
                    as="div"
                    className="flex justify-between items-center px-7 pb-2 mt-2 w-full border-b-[1px] border-gray-light border-"
                  >
                    <div className="flex gap-2 items-center">
                      <button onClick={closeModal}>
                        <XIcon
                          className={
                            'hover:stroke-gray-dark transition hover:scale-110'
                          }
                          width={26}
                          height={26}
                        />
                      </button>
                      <h3 className="text-lg font-medium text-gray-900">
                        {isNewReferral
                          ? 'Create New Referral'
                          : 'Edit Referral'}
                      </h3>
                    </div>
                  </Dialog.Title>
                  <section className="flex-col px-7 mt-1">
                    <div className="pt-4">
                      <h4
                        className={clsx(
                          'text-sm font-bold',
                          isTitleEmpty
                            ? 'text-red'
                            : 'text-gray-dark animate-none',
                        )}
                      >
                        Title*:
                      </h4>
                      <div
                        className={clsx(
                          'flex flex-row items-center py-2 px-6 my-1 w-full rounded-lg border',
                          isTitleEmpty
                            ? 'border-red animate-pulse'
                            : 'border-gray-light animate-none',
                        )}
                      >
                        <input
                          autoFocus
                          onFocus={() => setIsEditingReferralTitle(true)}
                          onBlur={() => setIsEditingReferralTitle(false)}
                          className="w-full text-sm focus:outline-none text-gray-dark"
                          onChange={(event) =>
                            setReferralTitle(event.target.value)
                          }
                          placeholder={referralTitlePlaceholder}
                          type="text"
                          value={referralTitle}
                          maxLength={maxTitleLength}
                        />
                        {isEditingReferralTitle && (
                          <p className="pl-2 text-xs">{`${titleLength}/${maxTitleLength}`}</p>
                        )}
                      </div>
                    </div>
                    <ReferralPatientInfo
                      patientType={patientType}
                      setPatientType={setPatientType}
                      patientGender={patientGender}
                      setPatientGender={setPatientGender}
                      patientInsurance={patientInsurance}
                      setPatientInsurance={setPatientInsurance}
                      patientModality={patientModality}
                      setPatientModality={setPatientModality}
                      patientLocation={patientLocation}
                      setPatientLocation={setPatientLocation}
                      requiredFieldsNotSubmitted={requiredFieldsNotSubmitted}
                    />

                    <div className="pt-1">
                      <h4 className="text-sm font-bold">Description:</h4>

                      <textarea
                        className={clsx(
                          referralContent ? '' : 'italic text-gray-mid',
                          'flex flex-row text-sm items-center my-1 py-2 px-6 w-full rounded-lg border border-gray-light',
                        )}
                        name="ReferralInput"
                        autoFocus
                        onChange={(event) =>
                          setReferralContent(event.target.value)
                        }
                        placeholder={referralContentPlaceholder}
                        id=""
                        cols={70}
                        rows={3}
                        value={referralContent || ''}
                      ></textarea>
                    </div>
                    <LinkPreview linkPreview={linkPreview} />
                    <div className="flex gap-2 justify-end items-center mt-5">
                      <CustomButton
                        onClick={
                          isNewReferral ? insertReferral : handleUpdateReferral
                        }
                        className={clsx(
                          'py-1 px-3 text-white text-sm rounded-md bg-primary hover:bg-purple-mid',
                        )}
                      >
                        {isNewReferral ? 'Submit' : 'Save'}
                      </CustomButton>
                      <CustomButton
                        onClick={closeModal}
                        className={clsx(
                          'py-1 px-3 text-black text-sm rounded-md bg-white border border-gray-mid hover:text-primary-hover  hover:border-purple-mid ',
                        )}
                      >
                        Cancel
                      </CustomButton>
                    </div>
                  </section>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}

export default ReferralFormModal
