import { GetHomeFeed_my_connections_posts_author } from 'operations/GetHomeFeed'

import { PencilAltIcon, TrashIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import Link from 'next/link'
import { FunctionComponent } from 'react'

import CurrentJob from 'components/common/current_job'
import { Avatar, Menu, Text } from 'components/ui'

interface AuthorProfileProps {
  author?: GetHomeFeed_my_connections_posts_author | undefined
  containerSize?: string
  imageWidth?: string
  imageHeight?: string
  isAnonymous: boolean
  children?: React.ReactNode
  onEdit?: () => void
  onDelete?: () => void
  showOptions?: boolean
  isMine?: boolean
  isComment?: boolean
  date?: string
}
const AuthorProfile: FunctionComponent<AuthorProfileProps> = ({
  author,
  containerSize = '50%',
  imageHeight = '36px',
  imageWidth = '36px',
  children,
  isAnonymous,
  onEdit = () => {},
  onDelete = () => {},
  isMine = false,
  showOptions = false,
  isComment = false,
  date = '',
}) => {
  return (
    <div className="flex gap-2 w-full grow">
      <div>
        {!author || isAnonymous ? (
          <Avatar
            profile={isAnonymous ? undefined : author}
            layout="fill"
            className="object-cover rounded-full"
            alt="Profile image"
            size={12}
            borderColor={isComment ? 'light-gray' : 'gray'}
          />
        ) : (
          <Link href={`/be/${author.username}`} passHref prefetch={false}>
            <Avatar
              profile={author}
              layout="fill"
              className="object-cover rounded-full"
              alt="Profile image"
              size={12}
              borderColor={isComment ? 'light-gray' : 'gray'}
            />
          </Link>
        )}
      </div>
      <div className="flex flex-col w-full">
        <div className="flex justify-between">
          <div className="flex gap-4 items-center text-sm font-bold capitalize">
            {isAnonymous ? (
              <Text variant="small" className="text-black" weight="semibold">
                Anonymous Member
              </Text>
            ) : (
              <Link
                href={`/be/${author?.username}`}
                passHref
                prefetch={false}
                legacyBehavior
              >
                <Text variant="small" className="text-black" weight="semibold">
                  {`${author?.first_name} ${author?.last_name}`.trim()}
                </Text>
              </Link>
            )}
            {showOptions && isMine && (
              <Menu
                direction="right"
                options={[
                  {
                    label: 'Edit',
                    icon: (active: boolean) => (
                      <PencilAltIcon
                        className={clsx(active && 'stroke-white', 'h-4 w-4')}
                      />
                    ),
                    onClick: onEdit,
                  },
                  {
                    label: 'Delete',
                    icon: (active: boolean) => (
                      <TrashIcon
                        className={clsx(active && 'stroke-white', 'h-4 w-4')}
                      />
                    ),
                    onClick: onDelete,
                  },
                ]}
              />
            )}
          </div>
          <Text variant="body" size="sm" className="flex-none text-gray-500">
            {date}
          </Text>
        </div>
        {(author?.current_job_new || author?.experiences.lenght > 0) &&
          !isAnonymous && (
            <CurrentJob
              className="text-xs truncate"
              currentJob={author?.current_job_new}
              experiences={author?.experiences}
            />
          )}
        {children && <div className="mt-2">{children}</div>}
      </div>
    </div>
  )
}

export default AuthorProfile
