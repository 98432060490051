import { GetReferrals_opportunities_author } from 'operations/GetReferrals'
import { InsertRespondant } from 'operations/InsertRespondant'

import {
  M_DELETE_OPPORTUNITY_RESPONDANTS,
  M_INSERT_RESPONDANT,
  U_RECOMMENDATION_STATE,
} from 'lib/mutations/opportunities'
import {
  Q_GET_OPPORTUNITY_TOTAL_RESPONDANTS,
  Q_GET_REFERRAL,
  Q_GET_REFERRALS,
  Q_GET_RESPONDANT,
} from 'lib/queries/opportunities'

import { useMutation } from '@apollo/client'
import { isEmpty } from 'ramda'
import { useContext, useState } from 'react'

import { useProfile } from 'components/hooks'
import { ProfileSubmittedContext } from 'components/opportunities/referrals/context/profile_submitted_context'
import { ResponseState } from 'components/opportunities/referrals/referral'
import { IReferralInfoInputs } from 'components/profile/referral_section/referral_information'

const useSubmitProfile = () => {
  const { profile: respondant } = useProfile()
  const [showWithdrawModal, setShowWithdrawModal] = useState(false)
  const [showSubmitModal, setShowSubmitModal] = useState(false)
  const [showDeclineModal, setShowDeclineModal] = useState(false)
  const [showReferralInfoEmptyModal, setShowReferralInfoEmptyModal] =
    useState(false)
  const [submitProfileComments, setSubmitProfileComments] = useState('')

  const { setReferralAuthorReceivingProfile, setShowConfirmationMessage } =
    useContext(ProfileSubmittedContext)

  const [insertRespondant] = useMutation<InsertRespondant>(
    M_INSERT_RESPONDANT,
    {
      refetchQueries: [
        { query: Q_GET_OPPORTUNITY_TOTAL_RESPONDANTS },
        'GetTotalRespondants',
        { query: Q_GET_RESPONDANT },
        'GetRespondant',
        { query: Q_GET_REFERRALS },
        'GetReferrals',
      ],
    },
  )

  const [withdrawProfile] = useMutation(M_DELETE_OPPORTUNITY_RESPONDANTS, {
    refetchQueries: [
      { query: Q_GET_OPPORTUNITY_TOTAL_RESPONDANTS },
      'GetTotalRespondants',
      { query: Q_GET_RESPONDANT },
      'GetRespondant',
      { query: Q_GET_REFERRALS },
      'GetReferrals',
    ],
  })

  const [updateRecommendationState] = useMutation(U_RECOMMENDATION_STATE, {
    refetchQueries: [
      { query: Q_GET_REFERRALS },
      'GetReferrals',
      { query: Q_GET_REFERRAL },
      'GetReferralById',
    ],
  })

  const checkReferralInformationValues = () => {
    const referralInformation = respondant?.referral_information
    const isEmailEmpty = isEmpty(referralInformation.email)
    const isPhoneEmpty = isEmpty(referralInformation.phone)
    const isReferralInfoEmpty = isEmpty(referralInformation)

    const hasEmptyFields = isEmailEmpty || isPhoneEmpty || isReferralInfoEmpty

    return hasEmptyFields
  }

  const handleButtonClicked = (isProfileSubmitted: boolean) => {
    if (checkReferralInformationValues()) {
      setShowReferralInfoEmptyModal(true)
      return
    }

    if (isProfileSubmitted) {
      setShowWithdrawModal(true)
    } else {
      setShowSubmitModal(true)
    }
  }

  const submitProfile = (
    referralId: number,
    referralAuthor: GetReferrals_opportunities_author,
  ) => {
    if (!respondant) return

    insertRespondant({
      variables: {
        from_profile_id: respondant?.id,
        opportunity_id: referralId,
        response_type: 'SUBMIT_PROFILE',
        response_state: 'ACCEPTED',
        submit_profile_message: submitProfileComments,
      },
    })
      .then(() => {
        setShowSubmitModal(false)
        setShowConfirmationMessage ? setShowConfirmationMessage(true) : null
        setReferralAuthorReceivingProfile
          ? setReferralAuthorReceivingProfile(referralAuthor || undefined)
          : null
      })
      .catch((e) =>
        // eslint-disable-next-line no-console
        console.error(
          'There was an error submitting your profile ' + e.message,
        ),
      )
  }

  const withdrawSubmittion = (referralId: number) => {
    withdrawProfile({
      variables: {
        fromProfileId: respondant?.id,
        opportunityId: referralId,
      },
    })
      .then(() => {
        setShowWithdrawModal(false)
      })
      .catch((e) =>
        // eslint-disable-next-line no-console
        console.error(
          'There was an error withdrawing your profile ' + e.message,
        ),
      )
  }

  const handleDecline = (referralId: number) => {
    updateRecommendationState({
      variables: {
        fromProfileId: respondant?.id,
        opportunityId: referralId,
        responseState: ResponseState.DECLINED,
      },
    }).then(() => setShowConfirmationMessage(false))
  }

  return {
    submitProfile,
    withdrawSubmittion,
    showWithdrawModal,
    setShowWithdrawModal,
    showSubmitModal,
    setShowSubmitModal,
    showReferralInfoEmptyModal,
    setShowReferralInfoEmptyModal,
    submitProfileComments,
    setSubmitProfileComments,
    handleButtonClicked,
    showDeclineModal,
    setShowDeclineModal,
    handleDecline,
  }
}

export default useSubmitProfile
