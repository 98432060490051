import { LinkPreviewData_link_preview } from 'operations/LinkPreviewData'

import { LinkIcon } from '@heroicons/react/outline'
import { isEmpty, isNil } from 'ramda'
import { FunctionComponent } from 'react'
import { twMerge } from 'tailwind-merge'

import { Text } from './ui'

interface LinkPreviewProps {
  linkPreview?: LinkPreviewData_link_preview | null
  className?: string
  textBoxClassName?: string
}

const LinkPreview: FunctionComponent<LinkPreviewProps> = ({
  linkPreview,
  className,
  textBoxClassName,
}) => {
  if (isNil(linkPreview)) return null

  const { url, title, absolute_image: image } = linkPreview

  if (isEmpty(url.trim())) return null

  return (
    <a target="_blank" href={url} rel="noopener noreferrer">
      <div className={twMerge('flex flex-col gap cursor-pointer', className)}>
        {!isNil(image) && (
          // eslint-disable-next-line
          <img
            src={image}
            alt={title}
            className="object-cover w-full h-52 md:h-80"
          />
        )}
        <div
          className={twMerge(
            'flex flex-col gap-2 px-7 py-4 bg-gray-100 border border-light-border',
            textBoxClassName,
          )}
        >
          <Text variant="heading">{title}</Text>
          <div className="flex gap-2 items-center max-w-xs">
            <div>
              <LinkIcon className="w-4 h-4 stroke-gray" />
            </div>
            <Text
              variant="extra-small"
              className="break-all line-clamp-1"
              detectLink={false}
            >
              {url}
            </Text>
          </div>
        </div>
      </div>
    </a>
  )
}

export default LinkPreview
