import { GetPostComments_post_comments } from 'operations/GetPostComments'
import { GetReferralComments_opportunity_comments } from 'operations/GetReferralComments'

import { FunctionComponent } from 'react'

import { getThreadedComments } from 'utils/posts/comments'

import Comment from './comment'

interface CommentListProps {
  comments: (
    | GetPostComments_post_comments
    | GetReferralComments_opportunity_comments
  )[]
  toPostId: number
  category: 'post' | 'opportunity'
  postAuthorId?: number
  isAnonymousPost?: boolean
  isOnFeed?: boolean
}
const CommentList: FunctionComponent<CommentListProps> = ({
  comments,
  toPostId,
  category = 'post',
  postAuthorId,
  isAnonymousPost,
  isOnFeed = false,
}) => {
  const threadedComments = getThreadedComments(comments)

  return (
    <div className="flex flex-col gap-4 w-full">
      {threadedComments.map((comment) => {
        const children = comment.children.sort((a, b) => a.id - b.id)
        return (
          <>
            <Comment
              category={category}
              key={comment.id}
              comment={comment}
              toPostId={toPostId}
              isAnonymousPost={isAnonymousPost}
              postAuthorId={postAuthorId}
              isOnFeed={isOnFeed}
            />
            {children.map((child) => (
              <div key={child.id}>
                <Comment
                  isReply
                  comment={child}
                  toPostId={toPostId}
                  category={category}
                  isAnonymousPost={isAnonymousPost}
                  postAuthorId={postAuthorId}
                  isOnFeed={isOnFeed}
                  isChild
                />
              </div>
            ))}
          </>
        )
      })}
    </div>
  )
}

export default CommentList
