import { GetReferrals_opportunities_opportunity_respondants_outsiders } from 'operations/GetReferrals'

const RespondantOutsider = ({
  respondant,
}: {
  respondant: GetReferrals_opportunities_opportunity_respondants_outsiders
}) => {
  return (
    <div className="flex ml-2">
      <div className="px-4">
        <input
          type="checkbox"
          value={respondant.id}
          name="respondant_outsider_profile"
        />
      </div>
      <div className="flex flex-col">
        <p className="text-sm text-gray-dark">
          {respondant.first_name} {respondant.last_name}
        </p>
        <p className="text-xs text-gray-mid">
          Contact email: {respondant.email}
        </p>
        <span className="text-xs truncate text-gray-mid">
          recommended by{' '}
          <span className="font-semibold text-gray-mid">
            {respondant.recommender?.first_name}{' '}
            {respondant.recommender?.last_name}
          </span>
        </span>
      </div>
    </div>
  )
}

export default RespondantOutsider
