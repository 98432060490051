import {
  FindLocations,
  FindLocations_locations,
} from 'operations/FindLocations'

import { Q_FIND_LOCATIONS } from 'lib/queries/locations'

import { gql, useQuery } from '@apollo/client'
import { Combobox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import { isEmpty, isNil } from 'ramda'
import { Dispatch, Fragment, SetStateAction, useState } from 'react'

export const LocationSelect = ({
  location,
  setLocation,
  requiredFieldsNotSubmitted,
}: {
  location: FindLocations_locations | null | undefined
  setLocation: Dispatch<
    SetStateAction<FindLocations_locations | null | undefined>
  >
  requiredFieldsNotSubmitted: boolean
}) => {
  const [queryValue, setQueryValue] = useState('')
  const {
    data: locationsData,
    loading,
    error,
  } = useQuery<FindLocations>(Q_FIND_LOCATIONS, {
    variables: { search: `%${queryValue.replace(/ +/g, '%')}%` },
  })

  const handleLocationChange = (val: any) => {
    if (val && !isEmpty(val)) {
      setQueryValue(val)
    }
  }

  const renderValue = (
    location: FindLocations_locations | null | undefined,
  ) => {
    if (location) {
      const { city, state } = location
      return [city, state].filter(() => !'').join(city && state ? ', ' : '')
    }
    return ''
  }

  const isLocationEmpty =
    requiredFieldsNotSubmitted && (isNil(location) || isEmpty(location))

  return (
    <div className="flex w-full">
      <Combobox value={location} onChange={setLocation}>
        <div className="relative mt-1 w-full">
          <div
            className={clsx(
              'overflow-hidden relative w-full max-h-10 text-left bg-white rounded-lg border cursor-default sm:text-sm focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300',
              isLocationEmpty
                ? 'border-red animate-pulse'
                : 'border-gray-light animate-none',
            )}
          >
            <Combobox.Input
              className="py-2 pr-10 pl-3 w-full text-sm leading-5 text-gray-900 border-none focus:ring-0"
              displayValue={() => (location ? `${renderValue(location)}` : '')}
              onChange={(event) => handleLocationChange(event.target.value)}
              placeholder="Type in a City or State"
            />
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Combobox.Options className="overflow-auto absolute py-1 mt-1 w-full max-h-60 text-base bg-white rounded-md ring-1 ring-black ring-opacity-5 shadow-lg sm:text-sm focus:outline-none">
              {locationsData?.locations.length === 0 ? (
                <div className="relative py-2 px-4 text-gray-700 cursor-default select-none">
                  Nothing found.
                </div>
              ) : (
                locationsData?.locations.map((location) => (
                  <Combobox.Option
                    key={location?.id}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? 'bg-purple-mid' : ''
                      }`
                    }
                    value={location}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected && active
                              ? 'font-medium text-white'
                              : 'font-normal'
                          } ${active ? 'bg-purple-mid text-white' : ''}
                          `}
                        >
                          {renderValue(location)}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? '' : ''
                            }`}
                          >
                            <CheckIcon
                              className={`h-5 w-5 ${
                                active ? 'stroke-white' : ''
                              }`}
                              aria-hidden="true"
                            />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  )
}
