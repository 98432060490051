import { Dialog, Transition } from '@headlessui/react'
import { CheckCircleIcon, XIcon } from '@heroicons/react/outline'
import { Fragment, FunctionComponent, useState } from 'react'

interface MessageModal {
  message: string
  showConfirmation: boolean
  setShowConfirmation: (value: boolean) => void
}

const MessageModal: FunctionComponent<MessageModal> = ({
  message,
  showConfirmation,
  setShowConfirmation,
}) => {
  return (
    <Transition appear show={showConfirmation} as={Fragment}>
      <Dialog
        as="div"
        onClose={() => setShowConfirmation(false)}
        className="relative z-10"
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="overflow-y-auto fixed inset-0">
          <div className="flex justify-center items-center p-4 min-h-full text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="overflow-hidden p-6 w-full max-w-md text-left align-middle bg-white rounded-2xl shadow-xl transition-all transform">
                <Dialog.Title
                  as="div"
                  className="flex justify-between items-center"
                >
                  <h3 className="flex gap-3 items-center text-lg font-medium leading-6 text-gray-900">
                    <CheckCircleIcon
                      width={22}
                      height={22}
                      className="stroke-[green]"
                    />
                    <span>{message}</span>
                  </h3>
                  <button onClick={() => setShowConfirmation(false)}>
                    <XIcon className="text-gray-mid" width={18} height={18} />
                  </button>
                </Dialog.Title>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default MessageModal
