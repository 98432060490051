import { GetProfileByOwnerId_profiles } from 'operations/GetProfileByOwnerId'
import type { GetReferrals_opportunities } from 'operations/GetReferrals'

import {
  M_UPDATE_OPPORTUNITY_FILLED_STATE,
  M_UPDATE_REFERRAL_STATUS,
} from 'lib/mutations/opportunities'
import { Q_GET_REFERRAL, Q_GET_REFERRALS } from 'lib/queries/opportunities'

import CommentList from '@/components/home/post/common/comment/comment_list'
import { parseReferralTitle } from '@/utils/index'
import { useMutation } from '@apollo/client'
import { ReplyIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import { formatDistanceToNow } from 'date-fns'
import Link from 'next/link'
import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useRef,
  useState,
} from 'react'

import BadgeCheckIcon from 'components/common/badge_check_icon'
import ConfirmationMessageModal from 'components/common/confirmation_message_modal'
import LinkifyWrapper from 'components/common/hocs/LinkifyWrapper'
import useOutsideClick from 'components/hooks/useOutsideClick'
import useProfile from 'components/hooks/useProfile'
import useSubmitComment from 'components/hooks/useSubmitComment'
import LinkPreview from 'components/link_preview'
import { Box, Modal } from 'components/ui'
import { Avatar } from 'components/ui'

import RecommendationList from './recommend_colleague/recommendation_list'
import ReferralFormModal from './referral_form_modal'
import ReferralOptions from './referral_options'
import ReferralPopupMenu from './referral_popup_menu'
import RespondantList from './respondant_list'

interface ReferralProps {
  referralData: GetReferrals_opportunities
  setIsEditingReferral?: Dispatch<SetStateAction<boolean>>
  setEditingReferralData?: Dispatch<
    SetStateAction<GetReferrals_opportunities | undefined>
  >
  isPage?: boolean
}

export enum ResponseState {
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  PENDING = 'PENDING',
}

type patientType =
  | 'under5'
  | '5-12'
  | '13-19'
  | '20-64'
  | '64+'
  | 'family'
  | 'couple'
  | ''
type patientGender = 'woman' | 'man' | 'non-binary' | 'transgender' | ''

type patientModality = 'online' | 'in-person' | 'both' | ''

const Referral: FunctionComponent<ReferralProps> = ({
  referralData,
  isPage,
}) => {
  const { profile: ownerProfile } = useProfile()
  const [openModal, setOpenModal] = useState(false)
  const [filledConfirmation, setFilledConfirmation] = useState(false)

  const menuRef = useRef<HTMLDivElement>(null)
  const [showPopupMenu, setShowPopupMenu] = useState(false)
  const hideMenu = () => {
    setShowPopupMenu(false)
  }
  useOutsideClick({ ref: menuRef, setState: hideMenu })

  const isReferralAuthor = ownerProfile?.id === referralData.author_profile_id
  const [isRespondantModalOpen, setIsRespondantModalOpen] = useState(false)
  const authorFullname = referralData.author
    ? `${referralData.author.first_name} ${referralData.author.last_name}`
    : ''

  const totalRespondantsData =
    referralData.opportunity_respondants.aggregate?.count || 0

  const totalRespondantsOutsiders =
    referralData.respondants_outsider.aggregate?.count || 0
  const totalRespondantsDeclinedData =
    referralData.respondants_declined.aggregate?.count || 0
  const totalRespondants = totalRespondantsData + totalRespondantsOutsiders

  const totalRespondantsFiltered =
    totalRespondants - totalRespondantsDeclinedData

  const comments = referralData.comments
  const referralUrl = `/opportunities/referrals/${
    referralData?.uuid
  }/${parseReferralTitle(referralData?.title)}`

  const { profile } = useProfile()

  const handleOpenRespondantsList = () => {
    setIsRespondantModalOpen(true)
  }

  const handleEditingReferral = () => {
    setShowPopupMenu(false)
    setOpenModal(true)
  }

  //patient variables
  const patientLocation = referralData?.seeking_info?.location?.city
    ? `${referralData?.seeking_info?.location?.city}, ${referralData?.seeking_info?.location?.state_code}`
    : referralData?.seeking_info?.location?.state
    ? `${referralData?.seeking_info?.location?.state}`
    : null

  const patientGender: patientGender = referralData?.seeking_info?.gender
  const patientGenderLabel = {
    woman: 'Woman',
    man: 'Man',
    'non-binary': 'Nonbinary/Nonconforming',
    transgender: 'transgender',
    '': null,
  }
  const patientType: patientType = referralData?.seeking_info?.type
  const patientTypeLabel = {
    under5: 'Under 5 years old',
    '5-12': '5-12 years old',
    '13-19': '13-19 years old',
    '20-64': '20-64 years old',
    '64+': '64+ years old',
    family: 'Family',
    couple: 'Couple',
    '': null,
  }

  const patientModality: patientModality = referralData?.seeking_info?.modality
  const patientModalityLabel = {
    online: 'Online',
    'in-person': 'In-Person',
    both: 'In-Person/Online',
    '': null,
  }

  const patientInsurance =
    referralData?.seeking_info?.insurance === 'yes'
      ? 'Provider accepts insurance'
      : null

  const [hiddenComments, setHiddenComments] = useState(false)
  const updateHiddenComments = () => {
    setHiddenComments(!hiddenComments)
  }

  const [isFilled, setIsFilled] = useState(referralData.filled)

  const [UpdateOpportunityFilledState] = useMutation(
    M_UPDATE_OPPORTUNITY_FILLED_STATE,
    {
      refetchQueries: [{ query: Q_GET_REFERRALS }, 'GetReferrals'],
    },
  )

  const openFilledConfirmation = () => {
    setFilledConfirmation(true)
    setShowPopupMenu(false)
  }
  const updateReferralFilledState = () => {
    setFilledConfirmation(false)
    setIsFilled(!isFilled)
    UpdateOpportunityFilledState({
      variables: {
        id: referralData?.id,
        filled: !isFilled,
      },
    })
    if (!isFilled) setIsFilled(true)
  }

  const { onPost, closeReply, isReplying } = useSubmitComment({
    postType: 'opportunity',
  })

  const [isVisible, setIsVisible] = useState(referralData?.live)

  const [updateReferralStatus] = useMutation(M_UPDATE_REFERRAL_STATUS, {
    refetchQueries: [
      { query: Q_GET_REFERRALS },
      'GetReferrals',
      { query: Q_GET_REFERRAL },
      'GetReferralById',
    ],
  })

  const toggleReferralVisibility = () => {
    setShowPopupMenu(false)
    setIsVisible(!isVisible)
    updateReferralStatus({
      variables: {
        id: referralData?.id,
        live: !isVisible,
      },
    })
  }

  return (
    <div>
      <Box className={isVisible ? 'card-content' : 'card-content-disabled'}>
        <div className="flex justify-end mt-2 mr-2">
          {isReferralAuthor && (
            <ReferralPopupMenu
              showPopupMenu={showPopupMenu}
              setShowPopupMenu={setShowPopupMenu}
              menuRef={menuRef}
              handleEditingReferral={handleEditingReferral}
              isVisible={isVisible}
              toggleReferralVisibility={toggleReferralVisibility}
              openReferralFilledConfirmation={openFilledConfirmation}
              isFilled={isFilled}
            />
          )}
          {isReferralAuthor && filledConfirmation && (
            <ConfirmationMessageModal
              message={`Are you sure you want to mark this referral as ${
                isFilled ? 'open' : 'filled'
              } ?`}
              showConfirmation={filledConfirmation}
              setShowConfirmation={setFilledConfirmation}
              onClickFunction={updateReferralFilledState}
            />
          )}
        </div>
        <div className="flex mt-4">
          <div className="flex flex-1 justify-center md:min-w-[20%] md:max-w-[20%] sm:min-w-[40%]  sm:max-w-[40%]">
            {referralData.author && (
              <div className="mr-2">
                <Link href={`/be/${referralData?.author?.username}`} passHref>
                  <Avatar
                    profile={referralData?.author}
                    layout="fill"
                    className="object-cover rounded-full"
                    alt="Profile image"
                    size={12}
                  />
                </Link>
              </div>
            )}
          </div>

          <Link href={referralUrl} passHref className="w-full">
            <div className="flex flex-col flex-initial w-full">
              <div className="flex justify-between w-full">
                <div className="flex flex-col">
                  <div className="flex justify-between items-center">
                    <h4
                      className={clsx(
                        'my-1 font-bold text-right uppercase text-primary text-xs',
                      )}
                    >
                      {referralData.category}
                    </h4>
                    <div className="ml-2"></div>
                  </div>

                  <div className="flex flex-row justify-between items-center">
                    <div className="text-sm font-bold capitalize md:text-[15px]">
                      <Link
                        href={`/be/${referralData?.author?.username}`}
                        legacyBehavior
                      >
                        {authorFullname}
                      </Link>
                      <Link href={referralUrl} passHref legacyBehavior>
                        <h4 className="mt-1 text-xs font-normal cursor-pointer hover:underline">
                          {formatDistanceToNow(
                            new Date(referralData?.created_at),
                          )}{' '}
                          ago
                        </h4>
                      </Link>
                    </div>
                  </div>
                </div>
                {isFilled && (
                  <div className="flex flex-col items-center">
                    <BadgeCheckIcon className="w-14 h-14 fill-blue-badge-check" />
                    <span className="text-xs font-bold text-blue-badge-check">
                      FILLED
                    </span>
                  </div>
                )}
              </div>
              <div className="flex justify-between w-full">
                <section className="max-w-full">
                  {isPage ? (
                    <a className="font-bold">{referralData?.title}</a>
                  ) : (
                    <Link href={referralUrl} className="font-bold">
                      {referralData?.title}
                    </Link>
                  )}
                  <div className="flex gap-3">
                    {(patientLocation || patientModality) && (
                      <div className="flex flex-col text-sm min-w-[4rem]">
                        {patientLocation && <span>{patientLocation}</span>}
                        {patientModality && (
                          <span>{patientModalityLabel[patientModality]}</span>
                        )}
                      </div>
                    )}
                    {(patientType || patientGender) && (
                      <div className="flex flex-col text-sm min-w-[4rem]">
                        {patientType && (
                          <span>{patientTypeLabel[patientType]}</span>
                        )}
                        {patientGender && (
                          <span>{patientGenderLabel[patientGender]}</span>
                        )}
                      </div>
                    )}
                  </div>
                  {patientInsurance && (
                    <div className="mt-2 text-sm">{patientInsurance}</div>
                  )}
                  {referralData.content && (
                    <div className="mt-2 w-full text-sm md:text-[15px]">
                      <LinkifyWrapper>
                        <p className="w-full whitespace-pre-wrap break-words">
                          {referralData?.content}
                        </p>
                      </LinkifyWrapper>
                    </div>
                  )}
                </section>
              </div>
              {referralData?.link_preview && (
                <LinkPreview linkPreview={referralData?.link_preview} />
              )}
            </div>
          </Link>
        </div>

        <section className="flex justify-end mt-4">
          <button
            onClick={isReferralAuthor ? handleOpenRespondantsList : () => null}
            className={
              isReferralAuthor
                ? 'flex items-center text-xs select-none text-gray-mid hover:text-green'
                : 'flex items-center text-xs select-none text-green cursor-default'
            }
          >
            <ReplyIcon className="mr-1 w-2 h-2" /> {totalRespondantsFiltered}{' '}
            {totalRespondantsFiltered == 1 ? 'response' : 'responses'}
          </button>
          <Modal
            isOpen={isRespondantModalOpen}
            onClose={() => setIsRespondantModalOpen(false)}
            title="List of Respondants"
          >
            <RespondantList
              referralId={referralData.id}
              respondants={{
                inBelongly: referralData.respondants,
                outBelongly: referralData.opportunity_respondants_outsiders,
              }}
            />
          </Modal>
        </section>

        {!isReferralAuthor &&
          ownerProfile &&
          referralData?.author_profile_id &&
          !isFilled && (
            <ReferralOptions
              ownerProfile={ownerProfile as GetProfileByOwnerId_profiles}
              referralData={referralData}
            />
          )}

        {!isFilled && referralData?.author?.id && (
          <div className="my-3">
            {/* <ReplyingBox
              category={'opportunity'}
              toPostId={referralData.id}
              isReply={false}
              closeReplyingBox={closeReply}
              onPost={onPost}
            /> */}
          </div>
        )}
        <RecommendationList
          recommendations={referralData.respondants.filter(
            (res) => res.recommender_profile !== null,
          )}
        />

        {profile && comments && (
          <CommentList
            category={'opportunity'}
            toPostId={referralData.id}
            comments={comments}
          />
        )}
      </Box>

      {openModal && (
        <ReferralFormModal
          isEditingReferral={openModal}
          setIsEditingReferral={setOpenModal}
          data={referralData}
          ownerProfile={ownerProfile as GetProfileByOwnerId_profiles}
        />
      )}
    </div>
  )
}

export default Referral
