import {
  GetReferrals_opportunities_opportunity_respondants_outsiders,
  GetReferrals_opportunities_respondants,
} from 'operations/GetReferrals'
import { InsertReferralList } from 'operations/InsertReferralList'
import {
  InsertReferralListSelectedProfiles,
  InsertReferralListSelectedProfilesVariables,
} from 'operations/InsertReferralListSelectedProfiles'
import { referral_list_profile_insert_input } from 'operations/globalTypes'

import {
  M_INSERT_REFERRAL_LIST,
  M_INSERT_REFERRAL_LIST_SELECTED_PROFILES,
} from 'lib/mutations/opportunities'

import { useMutation } from '@apollo/client'
import { XIcon } from '@heroicons/react/outline'
import { useRouter } from 'next/router'
import { isEmpty } from 'ramda'
import { FunctionComponent, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'

import { Button, Text } from 'components/ui'

import Respondant from './respondant'
import RespondantOutsider from './respondant_outsider'

interface IRespondantList {
  referralId: number
  respondants: {
    inBelongly: GetReferrals_opportunities_respondants[]
    outBelongly: GetReferrals_opportunities_opportunity_respondants_outsiders[]
  }
}

const RespondantList: FunctionComponent<IRespondantList> = ({
  referralId,
  respondants,
}) => {
  const router = useRouter()

  const [addReferralList] = useMutation<InsertReferralList>(
    M_INSERT_REFERRAL_LIST,
  )
  const [selectRespondants] = useMutation<
    InsertReferralListSelectedProfiles,
    InsertReferralListSelectedProfilesVariables
  >(M_INSERT_REFERRAL_LIST_SELECTED_PROFILES)

  const validatePeopleSelected = (selectedInputs: NodeList) => {
    let hasSelected = false
    selectedInputs?.forEach((input: any) => {
      if (input.checked) {
        hasSelected = true
      }
    })

    return hasSelected
  }

  const buildSelectedProfilesQuery = (
    selectedInputs: any,
    listId: number,
    isOutsider: boolean,
  ) => {
    const profiles: referral_list_profile_insert_input[] = []

    if (selectedInputs?.checked === undefined) {
      selectedInputs?.forEach((input: HTMLInputElement) => {
        if (input.checked) {
          const respondantId = Number(input.value)
          profiles.push({
            referral_list_id: listId,
            [isOutsider ? 'respondant_outsider_id' : 'respondant_id']:
              respondantId,
            status: true,
          })
        }
      })
    } else {
      if (selectedInputs?.checked) {
        const respondantId = Number(selectedInputs.value)
        profiles.push({
          referral_list_id: listId,
          [isOutsider ? 'respondant_outsider_id' : 'respondant_id']:
            respondantId,
          status: true,
        })
      }
    }

    return profiles
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    const responseItems = document.getElementsByName('respondant_profile')
    const selectedOutsiderRespondants =
      e.target?.elements['respondant_outsider_profile']

    if (
      !validatePeopleSelected(responseItems) &&
      (isEmpty(selectedOutsiderRespondants) || !selectedOutsiderRespondants)
    ) {
      toast.error('Please select at least one member')
      return
    }

    try {
      addReferralList({
        variables: { bodyText: '', opportunity_id: referralId },
      }).then(({ data }) => {
        const listId = data?.insert_referral_list_one?.id || 0

        const profiles = buildSelectedProfilesQuery(
          responseItems,
          listId,
          false,
        )
        const outsiders = buildSelectedProfilesQuery(
          selectedOutsiderRespondants,
          listId,
          true,
        )

        selectRespondants({
          variables: {
            selectedProfiles: [...profiles, ...outsiders],
          },
        }).then(() => {
          router.push(`/opportunities/referrals/lists/${listId}?state=new`)
        })
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error({ error })
    }
  }
  return (
    <>
      <Toaster />
      <ul>
        {respondants.inBelongly?.length === 0 &&
          respondants.outBelongly?.length === 0 && (
            <h2 className="font-semibold text-center text-primary">
              There are no respondents for this referral
            </h2>
          )}
        <form onSubmit={handleSubmit}>
          <div className="overflow-y-auto max-h-[320px]">
            {respondants.inBelongly?.length! > 0 && (
              <>
                <Text variant="heading">Members</Text>
                {respondants.inBelongly?.map((resp) => {
                  if (resp.response_state === 'DECLINED') return null
                  return (
                    <>
                      <li key={resp.id} className="w-full">
                        <Respondant
                          fromProfile={resp.from_profile}
                          recommender={resp.recommender_profile}
                          recommendationMessage={resp.recommender_message || ''}
                          respondantId={resp.id}
                        />
                      </li>
                    </>
                  )
                })}
              </>
            )}
            {respondants.outBelongly.length > 0 && (
              <>
                <hr className="mt-3 w-full text-gray-mid" />
                <Text variant="heading">Non Members</Text>
                <div className="flex flex-wrap gap-5">
                  {respondants.outBelongly.map((resp) => (
                    <>
                      <li key={resp.id}>
                        <RespondantOutsider key={resp.id} respondant={resp} />
                      </li>
                    </>
                  ))}
                </div>
              </>
            )}
          </div>
          {!(
            isEmpty(respondants.inBelongly) && isEmpty(respondants.outBelongly)
          ) && (
            <div className="flex justify-end mt-5">
              <Button type="submit" variant="primary">
                Create List
              </Button>
            </div>
          )}
        </form>
      </ul>
    </>
  )
}

export default RespondantList
