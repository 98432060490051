import React from 'react'

interface ButtonProps {
  className?: string
  children?: React.ReactNode
  disabled?: boolean
  hidden?: boolean
  onClick: () => void
}

const CustomButton: React.FC<ButtonProps> = ({
  className,
  children,
  disabled,
  hidden = false,
  onClick,
}) => {
  return (
    <>
      {!hidden && (
        <button className={className} onClick={onClick} disabled={disabled}>
          {children}
        </button>
      )}
    </>
  )
}

export default CustomButton
