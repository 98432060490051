import { GetReferrals_opportunities } from 'operations/GetReferrals'
import { InsertRespondant } from 'operations/InsertRespondant'

import {
  M_DELETE_OPPORTUNITY_RESPONDANTS,
  M_INSERT_RESPONDANT,
} from 'lib/mutations/opportunities'
import {
  Q_GET_OPPORTUNITY_TOTAL_RESPONDANTS,
  Q_GET_REFERRALS,
  Q_GET_RESPONDANT,
} from 'lib/queries/opportunities'
import { referralDataVar } from 'lib/reactiveVariables'

import { useMutation, useReactiveVar } from '@apollo/client'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import { isEmpty } from 'ramda'
import { Fragment, FunctionComponent, useContext, useState } from 'react'

import ConfirmationMessageModal from 'components/common/confirmation_message_modal'
import useProfile from 'components/hooks/useProfile'
import { IReferralInfoInputs } from 'components/profile/referral_section/referral_information'

import useSubmitProfile from '../../new-referrals/hooks/useSubmitProfile'
import { ProfileSubmittedContext } from '../context/profile_submitted_context'
import NoReferralInfoModal from '../no_referral_info_modal'

export const SubmitProfileModal = ({
  showConfirmationSubmitProfile,
  setShowConfirmationSubmitProfile,
  submitProfileComments,
  setSubmitProfileComments,
  handleSubmitMyProfile,
}: {
  showConfirmationSubmitProfile: boolean
  setShowConfirmationSubmitProfile: (value: boolean) => void
  submitProfileComments: string
  setSubmitProfileComments: (value: string) => void
  handleSubmitMyProfile: () => void
}) => (
  <Transition appear show={showConfirmationSubmitProfile} as={Fragment}>
    <Dialog
      as="div"
      onClose={() => setShowConfirmationSubmitProfile(false)}
      className="relative z-10"
    >
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-black bg-opacity-25" />
      </Transition.Child>

      <div className="overflow-y-auto fixed inset-0">
        <div className="flex justify-center items-center p-4 min-h-full text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="overflow-hidden p-6 w-full text-left align-middle bg-white rounded-2xl shadow-xl transition-all transform max-w-fit">
              <Dialog.Title
                as="div"
                className="flex gap-2 justify-between items-center"
              >
                <h3 className="self-start font-medium leading-6 text-gray-900 text-md">
                  <span>Submit Profile</span>
                </h3>
                <button
                  className=""
                  onClick={() => setShowConfirmationSubmitProfile(false)}
                >
                  <XIcon className="text-gray-mid" width={24} height={24} />
                </button>
              </Dialog.Title>
              <div className="pt-4">
                <h4 className={clsx('text-gray-dark animate-none text-sm')}>
                  Add a comment (optional):
                </h4>
                <div
                  className={clsx(
                    'flex flex-row items-center py-2 px-6 my-1 w-full rounded-lg border border-gray-light',
                  )}
                >
                  <textarea
                    className="w-full text-sm focus:outline-none text-gray-dark"
                    name="ReferralInput"
                    autoFocus
                    onChange={(event) =>
                      setSubmitProfileComments(event.target.value)
                    }
                    placeholder={'You can write a comment here'}
                    id=""
                    cols={50}
                    rows={3}
                    value={submitProfileComments || ''}
                  ></textarea>
                </div>
              </div>
              <div className="flex gap-2 justify-center mt-4">
                <button
                  className="py-1 px-3 w-20 text-sm text-white rounded-md bg-green hover:bg-green-light"
                  onClick={handleSubmitMyProfile}
                >
                  Submit
                </button>
                <button
                  className="py-1 px-3 w-20 text-sm text-black bg-white rounded-md border border-gray-mid hover:text-purple-main hover:border-purple-mid"
                  onClick={() => setShowConfirmationSubmitProfile(false)}
                >
                  Cancel
                </button>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition>
)

interface SubmitProfileProps {
  className?: string
  bgColor?: string
  children?: React.ReactNode
  isProfileSubmited?: boolean
  referralData?: GetReferrals_opportunities | undefined
}

const SubmitProfile: FunctionComponent<SubmitProfileProps> = ({
  referralData,
  className,
  bgColor,
  children,
  isProfileSubmited,
}) => {
  const [showConfirmationSubmitProfile, setShowConfirmationSubmitProfile] =
    useState(false)
  const [showConfirmationWithdrawProfile, setShowConfirmationWithdrawProfile] =
    useState(false)
  const [submitProfileComments, setSubmitProfileComments] = useState('')

  const { profile: respondant } = useProfile()
  const referralD = useReactiveVar(referralDataVar)

  const { showReferralInfoEmptyModal, setShowReferralInfoEmptyModal } =
    useSubmitProfile()

  const { setReferralAuthorReceivingProfile, setShowConfirmationMessage } =
    useContext(ProfileSubmittedContext)

  const [insertRespondant] = useMutation<InsertRespondant>(
    M_INSERT_RESPONDANT,
    {
      refetchQueries: [
        { query: Q_GET_OPPORTUNITY_TOTAL_RESPONDANTS },
        'GetTotalRespondants',
        { query: Q_GET_RESPONDANT },
        'GetRespondant',
        { query: Q_GET_REFERRALS },
        'GetReferrals',
      ],
    },
  )

  const [withdrawProfile] = useMutation(M_DELETE_OPPORTUNITY_RESPONDANTS, {
    refetchQueries: [
      { query: Q_GET_OPPORTUNITY_TOTAL_RESPONDANTS },
      'GetTotalRespondants',
      { query: Q_GET_RESPONDANT },
      'GetRespondant',
      { query: Q_GET_REFERRALS },
      'GetReferrals',
    ],
  })

  const checkReferralInformationValues = () => {
    const referralInformation =
      respondant?.referral_information as IReferralInfoInputs
    const isEmailEmpty = isEmpty(referralInformation.email)
    const isPhoneEmpty = isEmpty(referralInformation.phone)

    const hasEmptyFields = isEmailEmpty || isPhoneEmpty

    return hasEmptyFields
  }

  const handleSubmitMyProfile = () => {
    if (referralData) {
      referralDataVar(referralData)
      if (respondant && referralD) {
        insertRespondant({
          variables: {
            from_profile_id: respondant?.id,
            opportunity_id: referralD?.id,
            response_type: 'SUBMIT_PROFILE',
            response_state: 'ACCEPTED',
            submit_profile_message: submitProfileComments,
          },
        })
          .then(() => {
            setShowConfirmationSubmitProfile(false)
            setShowConfirmationMessage ? setShowConfirmationMessage(true) : null
            setReferralAuthorReceivingProfile
              ? setReferralAuthorReceivingProfile(
                  referralD?.author || undefined,
                )
              : null
          })
          .catch((e) =>
            // eslint-disable-next-line no-console
            console.error(
              'There was an error submitting your profile ' + e.message,
            ),
          )
      }
    }
  }

  const handleWithdrawProfile = () => {
    if (referralData) {
      withdrawProfile({
        variables: {
          fromProfileId: respondant?.id,
          opportunityId: referralData?.id,
        },
      })
        .then(() => {
          setShowConfirmationWithdrawProfile(false)
        })
        .catch((e) =>
          // eslint-disable-next-line no-console
          console.error(
            'There was an error withdrawing your profile ' + e.message,
          ),
        )
    }
  }

  const handleButtonClicked = () => {
    if (checkReferralInformationValues()) {
      setShowReferralInfoEmptyModal(true)
      return
    }
    if (isProfileSubmited) {
      setShowConfirmationWithdrawProfile(true)
    } else {
      setShowConfirmationSubmitProfile(true)
    }
  }

  return (
    <div>
      <button
        className={clsx(
          'text-white font-bold p-2 md:px-3 md:text-sm rounded-lg w-full disabled:opacity-85 disabled:bg-gray-mid',
          className,
          bgColor,
        )}
        onClick={handleButtonClicked}
      >
        {children || 'I can take this referral'}
      </button>
      <SubmitProfileModal
        showConfirmationSubmitProfile={showConfirmationSubmitProfile}
        setShowConfirmationSubmitProfile={setShowConfirmationSubmitProfile}
        submitProfileComments={submitProfileComments}
        setSubmitProfileComments={setSubmitProfileComments}
        handleSubmitMyProfile={handleSubmitMyProfile}
      />
      <ConfirmationMessageModal
        message="Are you sure you want to withdraw your profile submission?"
        showConfirmation={showConfirmationWithdrawProfile}
        setShowConfirmation={setShowConfirmationWithdrawProfile}
        onClickFunction={handleWithdrawProfile}
      />
      <NoReferralInfoModal
        setShowReferralInfoEmpty={setShowReferralInfoEmptyModal}
        showReferralInfoEmpty={showReferralInfoEmptyModal}
      />
    </div>
  )
}

export default SubmitProfile
