import { GetReferrals_opportunities_respondants } from 'operations/GetReferrals'
import { ProfileFields } from 'operations/ProfileFields'

import {
  M_DELETE_OPPORTUNITY_RESPONDANTS,
  U_RECOMMENDATION_STATE,
} from 'lib/mutations/opportunities'
import { Q_GET_REFERRALS } from 'lib/queries/opportunities'

import { useMutation } from '@apollo/client'
import { ChevronDownIcon, ChevronUpIcon, XIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FunctionComponent, useState } from 'react'

import ConfirmationMessageModal from 'components/common/confirmation_message_modal'
import LinkifyWrapper from 'components/common/hocs/LinkifyWrapper'
import useProfile from 'components/hooks/useProfile'
import useRecommendationSortByUser from 'components/hooks/useRecommendationSortByUser'
import { Avatar } from 'components/ui'

import { ResponseState } from '../referral'

const CustomProfile = ({
  profile,
  text,
}: {
  profile: ProfileFields
  text?: string
}) => (
  <Link href={`/be/${profile.username}`} passHref>
    <div className="flex gap-2 items-center w-full hover:cursor-pointer">
      <div>
        <Avatar
          profile={profile}
          layout="fill"
          className="inline object-cover rounded-full"
          alt="Profile image"
          size={8}
        />
      </div>
      <span className="font-bold truncate">
        {profile.first_name} {profile.last_name}{' '}
        <span className="font-normal">{text}</span>
      </span>
    </div>
  </Link>
)

const RecommendationItem = ({
  opportunityId,
  recommenderId,
  recommendationMessage,
  recommenderProfile,
  fromProfile,
  recommendationInfo,
}: {
  opportunityId: number
  recommenderId: number
  recommendationMessage: string
  recommenderProfile: ProfileFields
  fromProfile: ProfileFields

  recommendationInfo: GetReferrals_opportunities_respondants
}) => {
  const router = useRouter()

  const [showRemoveRecommendationModal, setShowRemoveRecommendationModal] =
    useState(false)
  const [removeReferralRecommendation] = useMutation(
    M_DELETE_OPPORTUNITY_RESPONDANTS,
    {
      refetchQueries: [{ query: Q_GET_REFERRALS }, 'GetReferrals'],
    },
  )

  const [updateRecommendationState] = useMutation(U_RECOMMENDATION_STATE, {
    refetchQueries: [{ query: Q_GET_REFERRALS }, 'GetReferrals'],
  })
  const { profile: ownerProfile } = useProfile()

  const handleRemoveRecommendation = () => {
    removeReferralRecommendation({
      variables: {
        fromProfileId: fromProfile.id,
        opportunityId: opportunityId,
      },
    })
    setShowRemoveRecommendationModal(false)
  }

  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false)

  const handleRecommendationResponse = () => {
    setShowConfirmationMessage(true)
  }

  const handleDecline = () => {
    updateRecommendationState({
      variables: {
        fromProfileId: fromProfile.id,
        opportunityId: opportunityId,
        responseState: ResponseState.DECLINED,
      },
    }).then(() => setShowConfirmationMessage(false))
  }

  const meetsConditionsToDecline =
    fromProfile.id === ownerProfile?.id &&
    recommendationInfo.response_state === ResponseState.PENDING

  return (
    <div>
      <div className="flex justify-between items-center p-4 w-full">
        <div className="flex flex-wrap gap-4">
          <CustomProfile profile={recommenderProfile} text="recommended" />
          <CustomProfile profile={fromProfile} />
          {recommendationInfo.response_state &&
            recommendationInfo.response_state !== ResponseState.PENDING && (
              <p
                className={clsx(
                  recommendationInfo.response_state === ResponseState.DECLINED
                    ? 'text-gray-mid'
                    : 'text-green',
                  'font-bold  self-center',
                )}
              >
                {recommendationInfo.response_state === ResponseState.DECLINED
                  ? 'Declined'
                  : 'Accepted'}
              </p>
            )}
          <ConfirmationMessageModal
            message={
              "Are you sure you want to decline this recommendation? \nYou can't undo this action, but you can reapply to the referral if you change your mind."
            }
            showConfirmation={showConfirmationMessage}
            setShowConfirmation={setShowConfirmationMessage}
            onClickFunction={handleDecline}
          />
        </div>
        {recommenderId == ownerProfile?.id && (
          <button
            className="rounded-sm hover:scale-110 h-fit bg-gray-light hover:bg-gray-mid"
            onClick={() => setShowRemoveRecommendationModal(true)}
          >
            <XIcon className="stroke-white" width={20} height={20} />
          </button>
        )}
        {meetsConditionsToDecline && (
          <button
            className="py-1 px-2 font-bold text-white rounded-lg bg-red/40 hover:bg-red/80"
            onClick={handleRecommendationResponse}
          >
            Decline
          </button>
        )}
      </div>
      {showRemoveRecommendationModal && (
        <ConfirmationMessageModal
          message="Are you sure you want to withdraw your recommendation?"
          showConfirmation={showRemoveRecommendationModal}
          setShowConfirmation={setShowRemoveRecommendationModal}
          onClickFunction={handleRemoveRecommendation}
        />
      )}

      {recommendationMessage && (
        <div className="py-2 text-sm text-left bg-white">
          <p className="px-4 pb-1 italic border-b shadow-sm border-gray-light">
            <LinkifyWrapper>{recommendationMessage}</LinkifyWrapper>
          </p>
        </div>
      )}
    </div>
  )
}

interface IRecommendationList {
  recommendations: GetReferrals_opportunities_respondants[]
}

const RecommendationList: FunctionComponent<IRecommendationList> = ({
  recommendations,
}) => {
  const [showAllRecommendations, setShowAllRecommendations] = useState(false)
  const totalRecom = recommendations.length
  const finalRecommendations = useRecommendationSortByUser({
    recommendations,
  })

  const handleShowAllRecommedations = () => {
    setShowAllRecommendations((prev) => !prev)
  }

  return (
    <div className="mt-5 w-full rounded-lg border border-gray-light">
      {finalRecommendations.slice(0, 2).map((recom, idx) => (
        <RecommendationItem
          recommendationInfo={recom}
          opportunityId={recom.opportunity_id as number}
          recommenderId={recom.recommender_profile?.id as number}
          fromProfile={recom.from_profile as ProfileFields}
          recommenderProfile={recom.recommender_profile as ProfileFields}
          recommendationMessage={recom.recommender_message as string}
          key={idx}
        />
      ))}
      {showAllRecommendations &&
        finalRecommendations
          .slice(2, totalRecom)
          .map((recom, idx) => (
            <RecommendationItem
              recommendationInfo={recom}
              opportunityId={recom.opportunity_id as number}
              recommenderId={recom.recommender_profile?.id as number}
              fromProfile={recom.from_profile as ProfileFields}
              recommenderProfile={recom.recommender_profile as ProfileFields}
              recommendationMessage={recom.recommender_message as string}
              key={idx}
            />
          ))}
      {totalRecom > 2 && (
        <button onClick={handleShowAllRecommedations} className="w-full">
          {!showAllRecommendations && (
            <span className="flex gap-3 justify-center items-center p-2 w-full text-gray-mid">
              Show {totalRecom - 2} more{' '}
              {totalRecom - 2 == 1 ? 'recommendation' : 'recommendations'}{' '}
              <ChevronDownIcon className="w-4 h-4 stroke-gray-mid" />
            </span>
          )}
          {showAllRecommendations && (
            <span className="flex gap-3 justify-center items-center p-2 w-full text-gray-mid">
              Show less <ChevronUpIcon className="w-4 h-4 stroke-gray-mid" />
            </span>
          )}
        </button>
      )}
    </div>
  )
}

export default RecommendationList
