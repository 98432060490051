import clsx from 'clsx'
import React, { MouseEventHandler } from 'react'

interface ButtonProps {
  className?: string
  onClick?: MouseEventHandler
}

const EditButton: React.FC<ButtonProps> = ({
  className,
  onClick,
  children,
}) => {
  return (
    <a
      onClick={onClick}
      className={clsx(className, 'cursor-pointer justify-center')}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        data-supported-dps="24x24"
        fill="currentColor"
        className="ml-auto mercado-match"
        width="24"
        height="24"
        focusable="false"
      >
        <path d="M21.13 2.86a3 3 0 00-4.17 0l-13 13L2 22l6.19-2L21.13 7a3 3 0 000-4.16zM6.77 18.57l-1.35-1.34L16.64 6 18 7.35z" />
      </svg>
      {children && <span className="font-semibold">{children}</span>}
    </a>
  )
}

export default EditButton
