import { U_RECOMMENDATION_STATE } from 'lib/mutations/opportunities'
import { Q_GET_REFERRALS } from 'lib/queries/opportunities'

import { useMutation } from '@apollo/client'
import { useState } from 'react'

import { ResponseState } from 'components/opportunities/referrals/referral'

const useRecommendationResponse = () => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [updateRecommendationState] = useMutation(U_RECOMMENDATION_STATE, {
    refetchQueries: [{ query: Q_GET_REFERRALS }, 'GetReferrals'],
  })

  const acceptRecommendation = (
    fromProfileId: number,
    opportunityId: number,
    responseState: ResponseState,
  ) => {
    if (responseState !== ResponseState.ACCEPTED) {
      updateRecommendationState({
        variables: {
          fromProfileId: fromProfileId,
          opportunityId: opportunityId,
          responseState: ResponseState.ACCEPTED,
        },
      })
    } else {
      setShowConfirmationModal(true)
    }
  }

  const declineRecommendation = (
    fromProfileId: number,
    opportunityId: number,
  ) => {
    updateRecommendationState({
      variables: {
        fromProfileId: fromProfileId,
        opportunityId: opportunityId,
        responseState: ResponseState.DECLINED,
      },
    })
      .then(() => {
        setShowConfirmationModal(false)
      })
      .catch((e) =>
        // eslint-disable-next-line no-console
        console.error(
          'There was an error withdrawing your profile ' + e.message,
        ),
      )
  }

  return {
    acceptRecommendation,
    declineRecommendation,
    showConfirmationModal,
    setShowConfirmationModal,
  }
}

export default useRecommendationResponse
