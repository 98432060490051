import { EyeIcon, UsersIcon } from '@heroicons/react/outline'
import { Dispatch, FunctionComponent, RefObject, SetStateAction } from 'react'

import BadgeCheckIcon from 'components/common/badge_check_icon'
import DotsIcon from 'components/common/dots_icon'
import EditButton from 'components/common/edit_button'
import HideIcon from 'components/common/hide_icon'

interface ReferralPopupMenuProps {
  showPopupMenu: boolean
  setShowPopupMenu: Dispatch<SetStateAction<boolean>>
  menuRef: RefObject<HTMLDivElement>
  handleEditingReferral: () => void
  isVisible: boolean
  toggleReferralVisibility: () => void
  openReferralFilledConfirmation: () => void
  isFilled: boolean
}

const ReferralPopupMenu: FunctionComponent<ReferralPopupMenuProps> = ({
  showPopupMenu,
  setShowPopupMenu,
  menuRef,
  handleEditingReferral,
  isVisible,
  toggleReferralVisibility,
  openReferralFilledConfirmation,
  isFilled,
}) => {
  return (
    <div className="relative">
      <a
        className="cursor-pointer"
        onClick={() => setShowPopupMenu(!showPopupMenu)}
      >
        <DotsIcon />
      </a>
      {showPopupMenu && (
        <div
          ref={menuRef}
          id="pop-menu"
          role="menu"
          className="flex absolute z-50 flex-col gap-4 items-start p-4 w-max bg-white rounded-md shadow-lg"
        >
          <div
            className="flex gap-2 cursor-pointer"
            onClick={handleEditingReferral}
          >
            <EditButton />
            <p className="ml-1">Edit</p>
          </div>
          {isVisible ? (
            <div
              className="flex gap-2 items-center cursor-pointer"
              onClick={toggleReferralVisibility}
            >
              <HideIcon className="flex self-center" />
              <p className="ml-1">Hide</p>
            </div>
          ) : (
            <div
              className="flex gap-2 items-center cursor-pointer"
              onClick={toggleReferralVisibility}
            >
              <EyeIcon className="flex w-6 h-6" />
              <p>Show</p>
            </div>
          )}
          <div
            className="flex gap-2 items-center cursor-pointer"
            onClick={openReferralFilledConfirmation}
          >
            <BadgeCheckIcon className={'h-6 w-6'} />

            <p>Mark as{isFilled ? ' Open' : ' Filled'}</p>
          </div>
        </div>
      )}
    </div>
  )
}

export default ReferralPopupMenu
