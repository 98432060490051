import { GetNameById } from 'operations/GetNameById'
import { GetPostComments_post_comments } from 'operations/GetPostComments'
import { GetReferralComments_opportunity_comments } from 'operations/GetReferralComments'

import { M_UPTADE_COMMENT } from 'lib/mutations/post_comment'
import { Q_GET_NAME_BY_ID } from 'lib/queries/profiles'

import { useLazyQuery, useMutation } from '@apollo/client'
import * as linkify from 'linkifyjs'
import { isNil } from 'ramda'
import { useEffect, useMemo, useState } from 'react'
import {
  capitalizeWord,
  getId,
  isMention,
  isTag,
  replaceMentions,
  whether,
} from 'utils'

import { MentionsInput } from 'components/common/hocs'
import useLinkPreview from 'components/hooks/usePreviewLink'
import LinkPreview from 'components/link_preview'
import { Button, Modal } from 'components/ui'

import AuthorProfile from '../author_profile'

const EditComment = ({
  isEditing,
  closeDialog,
  comment,
  isAnonymous,
}: {
  isEditing: boolean
  closeDialog: () => void
  isAnonymous: boolean
  comment:
    | GetPostComments_post_comments
    | GetReferralComments_opportunity_comments
}) => {
  const [commentText, setCommentText] = useState(comment.content)

  const [getProfileName] = useLazyQuery<GetNameById>(Q_GET_NAME_BY_ID)

  const linkPreviewUrl = useMemo(() => {
    const results = linkify.find(commentText)
    return whether(!isNil(results) && results.length > 0, results[0]?.href)
  }, [commentText])

  const linkPreview = useLinkPreview(linkPreviewUrl)

  const handleExistingMentions = () => {
    /** We are doing this way cause when we have mentions comming from the database
     * the second time this function is executed it will separate the profile name
     * in @[John Doe](1), so what we are doing is getting that pattern and then
     * if there are not a mentions we can proceed with the normal split, but if there
     * are we separate the words behind and after the mention and the we split it.
     */
    const wordsWithMentions = commentText.split(
      /(@\[[a-za-z]+\s[a-za-z]+\]\([0-9]\))/g,
    )

    const words: string[] = []
    if (wordsWithMentions.length > 1 && !isMention(wordsWithMentions[0])) {
      wordsWithMentions.map((word) => {
        if (isMention(word)) {
          words.push(word)
        }
        word.split(/(?=\s)|(?=^)/gm).map((w) => words.push(w))
      })
    } else {
      commentText.split(/(?=\s)|(?=^)/gm).map((w) => words.push(w))
    }

    const newWords = words.map(async (word) => {
      if (isTag(word)) {
        const { data } = await getProfileName({
          variables: {
            profileId: getId(word),
          },
        })
        const firstName = data?.profiles?.[0]?.first_name
        const lastName = data?.profiles?.[0]?.last_name

        const fullName = `${capitalizeWord(firstName ?? '')} ${capitalizeWord(
          lastName ?? '',
        )}`

        return ` @[${fullName}](${getId(word)})`
      }

      return word
    })

    return newWords
  }

  const [updateComment] = useMutation(M_UPTADE_COMMENT, {
    refetchQueries: [
      'GetPostComments',
      'GetReferrals',
      'GetReferralById',
      'GetHomeFeed',
      'GetPost',
    ],
  })

  const author = comment.author
  const authorFullname = author
    ? `${author.first_name} ${author.last_name}`
    : ''

  const handleChangeContent = (text: string) => {
    setCommentText(text)
  }

  const handleSave = () => {
    if (!isEditing) return

    updateComment({
      variables: { id: comment.id, content: replaceMentions(commentText) },
    })

    closeDialog()
  }

  useEffect(() => {
    Promise.all(handleExistingMentions()).then((results) => {
      results.forEach((result, index) => {
        setCommentText((prevValue) => {
          if (index == 0) {
            return result
          }

          return prevValue + result
        })
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Modal onClose={closeDialog} isOpen={isEditing} title="Edit Comment">
      <AuthorProfile
        containerSize="100%"
        author={comment?.author || undefined}
        isAnonymous={isAnonymous}
      >
        <div>
          <MentionsInput content={commentText} onChange={handleChangeContent} />
          <LinkPreview linkPreview={linkPreview} />
        </div>
        <Button
          className="mt-5 w-full max-h-10"
          variant="primary"
          onClick={handleSave}
        >
          Save
        </Button>
      </AuthorProfile>
    </Modal>
  )
}

export default EditComment
