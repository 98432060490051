import { FindLocations_locations } from 'operations/FindLocations'

import { RadioGroup } from '@headlessui/react'
import clsx from 'clsx'
import { isEmpty, isNil } from 'ramda'
import { Dispatch, FunctionComponent, SetStateAction, useState } from 'react'

import { LocationSelect } from './location_select'

interface ReferralPatientInfoProps {
  patientType: string
  setPatientType: Dispatch<SetStateAction<string>>
  patientGender: string
  setPatientGender: Dispatch<SetStateAction<string>>
  patientInsurance: string
  setPatientInsurance: Dispatch<SetStateAction<string>>
  patientModality: string
  setPatientModality: Dispatch<SetStateAction<string>>
  patientLocation: FindLocations_locations | null | undefined
  setPatientLocation: Dispatch<
    SetStateAction<FindLocations_locations | null | undefined>
  >
  requiredFieldsNotSubmitted: boolean
}

const ReferralPatientInfo: FunctionComponent<ReferralPatientInfoProps> = ({
  patientType,
  setPatientType,
  patientGender,
  setPatientGender,
  patientInsurance,
  setPatientInsurance,
  patientModality,
  setPatientModality,
  patientLocation,
  setPatientLocation,
  requiredFieldsNotSubmitted,
}) => {
  const optionContainerStyle =
    'flex py-2 px-2 mt-1 text-md rounded-lg cursor-pointer bg-gray-light gap-0.5 max-w-fit max-h-10 items-center'
  const radiobuttonStyle = {
    unselected:
      'hover:text-white hover:bg-primary/[0.35] hover:rounded-md py-0.5 px-2 flex whitespace-nowrap justify-self-center self-center select-none',
    selected:
      'bg-primary text-white font-medium rounded-md py-0.5 px-2 flex whitespace-nowrap justify-self-center select-none',
    disabled:
      'py-0.5 px-2 flex whitespace-nowrap justify-self-center self-center',
  }

  const isGenderDisabled = patientType == 'family' || patientType == 'couple'

  const handlePatientTypeChange = (type: string) => {
    if (!isGenderDisabled) {
      setPatientGender('')
    }
    setPatientType(type)
  }

  type PatientInfoCategories = 'type' | 'gender' | 'modality' | 'insurance'

  const CategoriesDictionary = {
    type: { categoryValue: patientType, setCategory: setPatientType },
    gender: { categoryValue: patientGender, setCategory: setPatientGender },
    modality: {
      categoryValue: patientModality,
      setCategory: setPatientModality,
    },
    insurance: {
      categoryValue: patientInsurance,
      setCategory: setPatientInsurance,
    },
  }

  const handleUnselect = (category: PatientInfoCategories, value: string) => {
    const { categoryValue, setCategory } = CategoriesDictionary[category]
    if (value === categoryValue) {
      setCategory('')
    }
  }

  const isLocationEmpty =
    requiredFieldsNotSubmitted &&
    (isNil(patientLocation) || isEmpty(patientLocation))

  return (
    <section className="flex flex-col gap-3 p-3 my-3 rounded-md border bg-gray-lighter border-gray-light">
      <h4 className="flex justify-center font-medium text-md">
        Patient Information
      </h4>
      <div className="flex gap-5">
        <div id="left-col" className="flex flex-col">
          <div className="flex flex-col mt-1 w-full">
            <h4
              className={clsx(
                'text-sm font-medium w-fit',
                isLocationEmpty ? 'text-red' : 'text-gray-dark animate-none',
              )}
            >
              Location*:
            </h4>
            <LocationSelect
              requiredFieldsNotSubmitted={requiredFieldsNotSubmitted}
              location={patientLocation}
              setLocation={setPatientLocation}
            />
          </div>
          <div id="typeOfPatient" className="flex w-full">
            <RadioGroup
              value={patientType}
              onChange={handlePatientTypeChange}
              className="mt-2"
            >
              <RadioGroup.Label className="text-sm font-medium">
                Age/Type:
              </RadioGroup.Label>
              <div className={optionContainerStyle}>
                <RadioGroup.Option
                  onClick={() => handleUnselect('type', 'under5')}
                  value="under5"
                >
                  {({ checked }) => (
                    <span
                      className={
                        checked
                          ? radiobuttonStyle.selected
                          : radiobuttonStyle.unselected
                      }
                    >
                      Under 5
                    </span>
                  )}
                </RadioGroup.Option>
                <RadioGroup.Option
                  onClick={() => handleUnselect('type', '5-12')}
                  value="5-12"
                >
                  {({ checked }) => (
                    <span
                      className={
                        checked
                          ? radiobuttonStyle.selected
                          : radiobuttonStyle.unselected
                      }
                    >
                      5-12
                    </span>
                  )}
                </RadioGroup.Option>
                <RadioGroup.Option
                  onClick={() => handleUnselect('type', '13-19')}
                  value="13-19"
                >
                  {({ checked }) => (
                    <span
                      className={
                        checked
                          ? radiobuttonStyle.selected
                          : radiobuttonStyle.unselected
                      }
                    >
                      13-19
                    </span>
                  )}
                </RadioGroup.Option>
                <RadioGroup.Option
                  onClick={() => handleUnselect('type', '20-64')}
                  value="20-64"
                >
                  {({ checked }) => (
                    <span
                      className={
                        checked
                          ? radiobuttonStyle.selected
                          : radiobuttonStyle.unselected
                      }
                    >
                      20-64
                    </span>
                  )}
                </RadioGroup.Option>
                <RadioGroup.Option
                  onClick={() => handleUnselect('type', '64+')}
                  value="64+"
                >
                  {({ checked }) => (
                    <span
                      className={
                        checked
                          ? radiobuttonStyle.selected
                          : radiobuttonStyle.unselected
                      }
                    >
                      64+
                    </span>
                  )}
                </RadioGroup.Option>
                <RadioGroup.Option
                  onClick={() => handleUnselect('type', 'couple')}
                  value="couple"
                >
                  {({ checked }) => (
                    <span
                      className={
                        checked
                          ? radiobuttonStyle.selected
                          : radiobuttonStyle.unselected
                      }
                    >
                      Couple
                    </span>
                  )}
                </RadioGroup.Option>
                <RadioGroup.Option
                  onClick={() => handleUnselect('type', 'family')}
                  value="family"
                >
                  {({ checked }) => (
                    <span
                      className={
                        checked
                          ? radiobuttonStyle.selected
                          : radiobuttonStyle.unselected
                      }
                    >
                      Family
                    </span>
                  )}
                </RadioGroup.Option>
              </div>
            </RadioGroup>
          </div>
          <div id="Gender" className="flex">
            <RadioGroup
              value={patientGender}
              onChange={setPatientGender}
              className={isGenderDisabled ? 'opacity-40' : 'mt-2'}
              disabled={isGenderDisabled}
            >
              <RadioGroup.Label className="text-sm font-medium">
                Gender:
              </RadioGroup.Label>
              <div className={optionContainerStyle}>
                <RadioGroup.Option
                  onClick={() => handleUnselect('gender', 'woman')}
                  value="woman"
                >
                  {({ checked }) => (
                    <span
                      className={
                        isGenderDisabled
                          ? radiobuttonStyle.disabled
                          : checked
                          ? radiobuttonStyle.selected
                          : radiobuttonStyle.unselected
                      }
                    >
                      Woman
                    </span>
                  )}
                </RadioGroup.Option>
                <RadioGroup.Option
                  onClick={() => handleUnselect('gender', 'man')}
                  value="man"
                >
                  {({ checked }) => (
                    <span
                      className={
                        isGenderDisabled
                          ? radiobuttonStyle.disabled
                          : checked
                          ? radiobuttonStyle.selected
                          : radiobuttonStyle.unselected
                      }
                    >
                      Man
                    </span>
                  )}
                </RadioGroup.Option>
                <RadioGroup.Option
                  onClick={() => handleUnselect('gender', 'non-binary')}
                  value="non-binary"
                >
                  {({ checked }) => (
                    <span
                      className={
                        isGenderDisabled
                          ? radiobuttonStyle.disabled
                          : checked
                          ? radiobuttonStyle.selected
                          : radiobuttonStyle.unselected
                      }
                    >
                      Nonbinary/Nonconforming
                    </span>
                  )}
                </RadioGroup.Option>
                <RadioGroup.Option
                  onClick={() => handleUnselect('gender', 'transgender')}
                  value="transgender"
                >
                  {({ checked }) => (
                    <span
                      className={
                        isGenderDisabled
                          ? radiobuttonStyle.disabled
                          : checked
                          ? radiobuttonStyle.selected
                          : radiobuttonStyle.unselected
                      }
                    >
                      Transgender
                    </span>
                  )}
                </RadioGroup.Option>
              </div>
            </RadioGroup>
          </div>
        </div>
        <div id="right-col" className="flex flex-col">
          <div id="modality" className="flex">
            <RadioGroup
              value={patientModality}
              onChange={setPatientModality}
              className=""
            >
              <RadioGroup.Label className="text-sm font-medium">
                Modality:
              </RadioGroup.Label>
              <div className={optionContainerStyle}>
                <RadioGroup.Option
                  onClick={() => handleUnselect('modality', 'online')}
                  value="online"
                >
                  {({ checked }) => (
                    <span
                      className={
                        checked
                          ? radiobuttonStyle.selected
                          : radiobuttonStyle.unselected
                      }
                    >
                      Online
                    </span>
                  )}
                </RadioGroup.Option>
                <RadioGroup.Option
                  onClick={() => handleUnselect('modality', 'in-person')}
                  value="in-person"
                >
                  {({ checked }) => (
                    <span
                      className={
                        checked
                          ? radiobuttonStyle.selected
                          : radiobuttonStyle.unselected
                      }
                    >
                      In-Person
                    </span>
                  )}
                </RadioGroup.Option>
                <RadioGroup.Option
                  onClick={() => handleUnselect('modality', 'both')}
                  value="both"
                >
                  {({ checked }) => (
                    <span
                      className={
                        checked
                          ? radiobuttonStyle.selected
                          : radiobuttonStyle.unselected
                      }
                    >
                      Both
                    </span>
                  )}
                </RadioGroup.Option>
              </div>
            </RadioGroup>
          </div>
          <div id="Insurance" className="flex">
            <RadioGroup
              value={patientInsurance}
              onChange={setPatientInsurance}
              className="mt-2"
            >
              <RadioGroup.Label className="text-sm font-medium">
                Provider Accepts Insurance:
              </RadioGroup.Label>
              <div className={optionContainerStyle}>
                <RadioGroup.Option
                  onClick={() => handleUnselect('insurance', 'yes')}
                  value="yes"
                >
                  {({ checked }) => (
                    <span
                      className={
                        checked
                          ? radiobuttonStyle.selected
                          : radiobuttonStyle.unselected
                      }
                    >
                      Yes
                    </span>
                  )}
                </RadioGroup.Option>
                <RadioGroup.Option
                  onClick={() => handleUnselect('insurance', 'no')}
                  value="no"
                >
                  {({ checked }) => (
                    <span
                      className={
                        checked
                          ? radiobuttonStyle.selected
                          : radiobuttonStyle.unselected
                      }
                    >
                      No
                    </span>
                  )}
                </RadioGroup.Option>
              </div>
            </RadioGroup>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ReferralPatientInfo
