import { GetReferrals_opportunities_author } from 'operations/GetReferrals'

import { Dispatch, SetStateAction, createContext, useContext } from 'react'

export type ProfileSubmittedContextType = {
  setReferralAuthorReceivingProfile: Dispatch<
    SetStateAction<GetReferrals_opportunities_author | undefined>
  >
  setShowConfirmationMessage: Dispatch<SetStateAction<boolean>>
}

export const ProfileSubmittedContext =
  createContext<ProfileSubmittedContextType>({
    setReferralAuthorReceivingProfile: () => {},
    setShowConfirmationMessage: () => {},
  })

export const useProfileSubmittedContext = () =>
  useContext(ProfileSubmittedContext)
