import { GetPostComments_post_comments } from 'operations/GetPostComments'
import { GetReferralComments_opportunity_comments } from 'operations/GetReferralComments'

import { isNil } from 'ramda'

const commentExist = ({
  comments,
  id,
}: {
  comments: (
    | GetPostComments_post_comments
    | GetReferralComments_opportunity_comments
  )[]
  id: number
}) => comments.some((comment) => comment.id === id)

const getMainComments = (
  comments: (
    | GetPostComments_post_comments
    | GetReferralComments_opportunity_comments
  )[],
) =>
  comments.filter(
    ({ parent_id: parent }) =>
      isNil(parent) || !commentExist({ comments, id: parent }),
  ) || []

export const getThreadedComments = (
  comments: (
    | GetPostComments_post_comments
    | GetReferralComments_opportunity_comments
  )[],
) =>
  getMainComments(comments).map((parent) => {
    const children = comments.filter(
      (comment) => comment.parent_id === parent.id,
    )
    return { ...parent, children }
  })
