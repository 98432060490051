import { GetReferrals_opportunities_respondants } from 'operations/GetReferrals'

import { U_RECOMMENDATION_STATE } from 'lib/mutations/opportunities'
import { Q_GET_REFERRALS } from 'lib/queries/opportunities'

import { useMutation } from '@apollo/client'
import clsx from 'clsx'
import { FunctionComponent, useState } from 'react'

import ConfirmationMessageModal from 'components/common/confirmation_message_modal'

import { ResponseState } from './referral'

interface RecommendationResponseProps {
  recommendationInfo: GetReferrals_opportunities_respondants
}

const RecommendationResponse: FunctionComponent<
  RecommendationResponseProps
> = ({ recommendationInfo }) => {
  const [updateRecommendationState] = useMutation(U_RECOMMENDATION_STATE, {
    refetchQueries: [{ query: Q_GET_REFERRALS }, 'GetReferrals'],
  })

  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false)

  const handleRecommendationResponse = () => {
    if (recommendationInfo.response_state !== ResponseState.ACCEPTED) {
      updateRecommendationState({
        variables: {
          fromProfileId: recommendationInfo.from_profile_id,
          opportunityId: recommendationInfo.opportunity_id,
          responseState: ResponseState.ACCEPTED,
        },
      })
    } else {
      setShowConfirmationMessage(true)
    }
  }

  const handleWithdrawProfile = () => {
    updateRecommendationState({
      variables: {
        fromProfileId: recommendationInfo.from_profile_id,
        opportunityId: recommendationInfo.opportunity_id,
        responseState: ResponseState.DECLINED,
      },
    })
      .then(() => {
        setShowConfirmationMessage(false)
      })
      .catch((e) =>
        // eslint-disable-next-line no-console
        console.error(
          'There was an error withdrawing your profile ' + e.message,
        ),
      )
  }

  return (
    <div>
      <button
        className={clsx(
          recommendationInfo.response_state !== ResponseState.ACCEPTED
            ? 'bg-green hover:bg-green-light'
            : 'bg-gray-mid hover:bg-gray-dark',
          'text-white font-bold p-2 md:px-3 md:text-sm rounded-lg w-full',
        )}
        onClick={handleRecommendationResponse}
      >
        {recommendationInfo.response_state !== ResponseState.ACCEPTED
          ? 'I´m Interested!'
          : 'Withdraw My Profile'}
      </button>
      <ConfirmationMessageModal
        message="Are you sure you want to withdraw your profile submission?"
        showConfirmation={showConfirmationMessage}
        setShowConfirmation={setShowConfirmationMessage}
        onClickFunction={handleWithdrawProfile}
      />
    </div>
  )
}

export default RecommendationResponse
