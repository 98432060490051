import { isEmpty } from 'ramda'
import { FunctionComponent, useState } from 'react'

import { MentionsInput, PermissionWrapper } from 'components/common/hocs'
import useProfile from 'components/hooks/useProfile'
import { Avatar, Button, Text } from 'components/ui'

import InsertOptions from './insert_options'

interface ReplyingBoxProps {
  category: 'post' | 'opportunity'
  toPostId: number
  isReply: boolean
  closeReplyingBox: () => void
  onPost: ({
    comment,
    postId,
    isAnonymous,
  }: {
    comment: string
    postId: number
    isAnonymous: boolean
  }) => void
  isAnonymous: boolean
  postAuthorId: number
  postAuthor?: any
  parentCommentAuthorId?: number
  isAnonymousPost?: boolean
  autoFocus?: boolean
  replyingTo?: string
  closeReply?: () => void
}

const ReplyingBox: FunctionComponent<ReplyingBoxProps> = ({
  toPostId,
  isReply,
  onPost,
  category,
  isAnonymous,
  postAuthorId,
  postAuthor,
  autoFocus,
}) => {
  const { profile: ownerProfile } = useProfile()
  const [comment, setComment] = useState<string>('')
  const [isAnonymousComment, setIsAnonymousComment] = useState(
    isAnonymous && ownerProfile?.id === postAuthor,
  )
  const isPostAuthor = postAuthorId === ownerProfile?.id

  const isEditing = !isEmpty(comment.trim())

  const handleOnPost = () => {
    setComment('')
    onPost({ comment, postId: toPostId, isAnonymous: isAnonymousComment })
  }

  const preSelectedMentions = postAuthor
    ? [
        {
          id: postAuthor.id,
          avatar: postAuthor.avatar,
          last_name: postAuthor.last_name,
          first_name: postAuthor.first_name,
        },
      ]
    : []

  return (
    <div className="flex gap-7 items-center mb-10 w-full">
      {!isAnonymous && (
        <div>
          <Avatar
            profile={ownerProfile}
            layout="fill"
            className="object-cover rounded-full"
            alt="Profile image"
            size={12}
          />
        </div>
      )}
      {ownerProfile && category === 'post' && isPostAuthor && isAnonymous && (
        <InsertOptions
          isAnonymousPost={isAnonymousComment}
          options={[
            {
              label: `Post as ${ownerProfile?.first_name} ${ownerProfile?.last_name}`,
              onClick: () => setIsAnonymousComment(false),
            },
            {
              label: 'Post Anonymously',
              onClick: () => setIsAnonymousComment(true),
            },
          ]}
        />
      )}
      <div className="flex flex-col w-full group">
        <div className="flex gap-2 w-full h-full">
          <div className="flex w-full">
            <PermissionWrapper
              action="CommentPost"
              behavior="Disable"
              tooltip="You dont have permissions to post comments yet. Your membership application is still being reviewed. Once approved you will have full access. In the meantime you can complete your profile and view different areas of Belongly."
            >
              <MentionsInput
                placeholder={isReply ? 'Reply' : 'Add to the discussion'}
                content={comment}
                onChange={setComment}
                autoFocus={autoFocus}
                className="w-full"
                preSelectedProfiles={preSelectedMentions}
              />
            </PermissionWrapper>
          </div>
          {isEditing && (
            <PermissionWrapper
              action="CommentPost"
              behavior="Disable"
              tooltip="You dont have permissions to post comments yet. Your membership application is still being reviewed. Once approved you will have full access. In the meantime you can complete your profile and view different areas of Belongly."
            >
              <Button disabled={!isEditing} onClick={handleOnPost}>
                <Text weight="semibold" className="w-full" color="primary">
                  {isReply ? 'Reply' : 'Post'}
                </Text>
              </Button>
            </PermissionWrapper>
          )}
        </div>
      </div>
    </div>
  )
}

export default ReplyingBox
