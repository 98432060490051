import { InsertRespondant } from 'operations/InsertRespondant'
import { InsertRespondantOutsiderVariables } from 'operations/InsertRespondantOutsider'
import { UserSearch_profiles } from 'operations/UserSearch'

import {
  M_INSERT_RESPONDANT,
  M_INSERT_RESPONDANT_OUTSIDER,
  Q_RECOMMENDATION_INVITE,
} from 'lib/mutations/opportunities'

import { PermissionWrapper } from '@/components/common/hocs'
import { useLazyQuery, useMutation } from '@apollo/client'
import clsx from 'clsx'
import { Dispatch, FunctionComponent, SetStateAction, useState } from 'react'

import MessageModal from 'components/common/message_modal'
import useProfile from 'components/hooks/useProfile'
import { Button, Modal } from 'components/ui'

import RecomendationOutsideBelongly from './recommend_outside_belongly'
import RecommendationSelect from './recommendation_select'
import RecommendationSelected from './recommendation_selected'

interface IRecommendColleague {
  referralId: number
  referralTitle: string
  isRecommendColleagueOpen: boolean
  setIsRecommendColleague: Dispatch<SetStateAction<boolean>>
}

export type SelectedInvitee = {
  message?: string
} & UserSearch_profiles

const RecommendColleague: FunctionComponent<IRecommendColleague> = ({
  referralId,
  referralTitle,
  isRecommendColleagueOpen,
  setIsRecommendColleague,
}) => {
  const [hasErrors, setHasErrors] = useState(false)
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false)
  const [isRecommendingOutsideBelongly, setIsRecommendingOutsideBelongly] =
    useState(false)

  //Recommend someone outside Belongly
  const [recommendationInfo, setRecommendationInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
  })

  const [recommendForeigner] = useLazyQuery(Q_RECOMMENDATION_INVITE)
  const [insertRespondantOutsider] =
    useMutation<InsertRespondantOutsiderVariables>(
      M_INSERT_RESPONDANT_OUTSIDER,
      {
        refetchQueries: [
          'GetTotalRespondants',
          'GetRespondant',
          'GetReferrals',
          'GetTotalRespondants',
          'GetTotalRespondantsDeclined',
        ],
      },
    )

  const [selectedInvitee, setSelectedInvitee] =
    useState<SelectedInvitee | null>(null)

  const { profile: ownerProfile } = useProfile()

  const [insertRespondant] = useMutation<InsertRespondant>(
    M_INSERT_RESPONDANT,
    {
      refetchQueries: ['GetTotalRespondants', 'GetRespondant', 'GetReferrals'],
    },
  )

  const closeRecommendColleagueModal = () => {
    setIsRecommendColleague(false)
  }

  const handleAddInvitee = (invitees: SelectedInvitee) => {
    setSelectedInvitee(invitees)
  }

  const handleAddMessage = (message: string) => {
    const newList = { ...selectedInvitee, message }
    setSelectedInvitee(newList as unknown as any)
  }

  const handleRemoveInvitee = () => {
    setSelectedInvitee(null)
  }

  const handleCancel = () => {
    setIsRecommendColleague(false)
    setSelectedInvitee(null)
    setHasErrors(false)
  }

  const handleRecommend = () => {
    if (!isRecommendingOutsideBelongly) {
      if (!selectedInvitee) return

      insertRespondant({
        variables: {
          from_profile_id: selectedInvitee.id,
          opportunity_id: referralId,
          recommender_id: ownerProfile?.id,
          recommender_message: selectedInvitee.message || '',
          response_type: 'RECOMMEND',
          response_state: 'PENDING',
        },
      }).then(() => {
        setSelectedInvitee(null)
        setIsRecommendColleague(false)
      })
    } else {
      recommendForeigner({
        variables: {
          first_name: recommendationInfo.firstName,
          last_name: recommendationInfo.lastName,
          email: recommendationInfo.email,
          referral_title: referralTitle,
          recommender_name: `${ownerProfile?.first_name}  ${ownerProfile?.last_name}`,
        },
      }).then(({ error }) => {
        if (!error) {
          insertRespondantOutsider({
            variables: {
              first_name: recommendationInfo.firstName,
              last_name: recommendationInfo.lastName,
              email: recommendationInfo.email,
              opportunity_id: referralId,
              recommender_id: ownerProfile?.id,
            },
          }).then(({ errors }) => {
            if (!errors) {
              setHasErrors(false)
              setIsRecommendColleague(false)
              setShowConfirmationMessage(true)
            } else {
              setHasErrors(true)
            }
          })
        } else {
          setHasErrors(true)
        }
      })
    }
  }

  const handleRecommendOutsideBelongly = () => {
    setHasErrors(false)
    if (isRecommendingOutsideBelongly) setIsRecommendingOutsideBelongly(false)
    else {
      isRecommendingOutsideBelongly
      setIsRecommendingOutsideBelongly(true)
    }
  }

  return (
    <>
      <Modal
        isOpen={isRecommendColleagueOpen}
        onClose={closeRecommendColleagueModal}
        title="Recommend a Colleague"
      >
        <div className="flex overflow-y-auto flex-col justify-between mt-6 min-h-[300px] max-h-[600px]">
          <div>
            {isRecommendingOutsideBelongly ? (
              <RecomendationOutsideBelongly
                recommendationInfo={recommendationInfo}
                setRecommendationInfo={setRecommendationInfo as ({}) => void}
              />
            ) : (
              <RecommendationSelect
                addInvitee={handleAddInvitee}
                selectedValue={selectedInvitee}
              />
            )}
            {hasErrors && (
              <p className="text-xs text-red">Oh sorry something happend.</p>
            )}
            {
              <button
                className="flex items-center mt-4 ml-1"
                onClick={handleRecommendOutsideBelongly}
              >
                <p className="text-xs italic underline text-purple-main">
                  {isRecommendingOutsideBelongly
                    ? 'Know someone on Belongly who would be a good fit?'
                    : 'Know someone NOT on Belongly who would be a good fit?'}
                </p>
              </button>
            }
            {selectedInvitee && !isRecommendingOutsideBelongly && (
              <div className="flex overflow-y-auto flex-wrap my-3 w-full max-h-[450px]">
                <RecommendationSelected
                  invitee={selectedInvitee}
                  removeInvitee={handleRemoveInvitee}
                  addMessage={handleAddMessage}
                />
              </div>
            )}
          </div>
          <section className="flex gap-2 justify-end w-full">
            {isRecommendingOutsideBelongly ? (
              <Button variant="primary" onClick={handleRecommend}>
                Recommend
              </Button>
            ) : (
              <PermissionWrapper
                behavior="Disable"
                action="SubmitRecommendedColleague"
                tooltip="The person you are recommending is not approved yet."
                tooltipPlace="left"
                variables={{
                  responseType: 'RECOMMEND',
                  recommenderProfile: selectedInvitee,
                }}
              >
                <Button variant="primary" onClick={handleRecommend}>
                  Recommend
                </Button>
              </PermissionWrapper>
            )}
            <Button variant="third" onClick={handleCancel}>
              Cancel
            </Button>
          </section>
        </div>
      </Modal>
      <MessageModal
        message={`Recommendation email sent to ${recommendationInfo.firstName} ${recommendationInfo.lastName}`}
        showConfirmation={showConfirmationMessage}
        setShowConfirmation={setShowConfirmationMessage}
      />
    </>
  )
}

export default RecommendColleague
