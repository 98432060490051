import { InformationCircleIcon } from '@heroicons/react/outline'
import Link from 'next/link'
import { FunctionComponent, SetStateAction } from 'react'

import useProfile from 'components/hooks/useProfile'
import { Modal } from 'components/ui'

interface NoReferralInfoModalProps {
  showReferralInfoEmpty: boolean
  setShowReferralInfoEmpty: (value: SetStateAction<boolean>) => void
}

const NoReferralInfoModal: FunctionComponent<NoReferralInfoModalProps> = ({
  showReferralInfoEmpty,
  setShowReferralInfoEmpty,
}) => {
  const { profile } = useProfile()

  return (
    <Modal
      isOpen={showReferralInfoEmpty}
      onClose={() => setShowReferralInfoEmpty(false)}
    >
      <span className="flex gap-2 items-center">
        <InformationCircleIcon className="flex-none w-6 h-6 stroke-red" />
        <p>
          In order to take referrals you must complete the My Referral
          Information on your profile. Please{' '}
          <Link href={`/be/${profile?.username}`} className="text-secondary">
            click here
          </Link>{' '}
          to go to your profile and register you email and phone number now.
          Thank you!
        </p>
      </span>
    </Modal>
  )
}

export default NoReferralInfoModal
