import { FunctionComponent } from 'react'

import DropDownArrowIcon from 'components/common/drop_down_arrow_icon'
import { useProfile } from 'components/hooks'
import { Avatar, Menu as MMenu } from 'components/ui'

export type InsertOptionType = {
  label: string
  onClick: () => void
}

interface IInsertOptions {
  isAnonymousPost: boolean
  options: InsertOptionType[]
}

const InsertOptions: FunctionComponent<IInsertOptions> = ({
  isAnonymousPost,
  options,
}) => {
  const { profile } = useProfile()

  return (
    <MMenu
      options={options}
      direction="right"
      button={
        <div className="flex items-center rounded hover:bg-gray-100">
          <div>
            <Avatar
              profile={isAnonymousPost ? undefined : profile}
              layout="fill"
              className="object-cover rounded-full"
              alt="Profile image"
              size={12}
            />
          </div>
          <DropDownArrowIcon />
        </div>
      }
    />
  )
}

export default InsertOptions
