import { GetHomeFeed_my_connections_posts_author } from 'operations/GetHomeFeed'
import { GetReferrals_opportunities_respondants_from_profile } from 'operations/GetReferrals'

import { Transition } from '@headlessui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline'
import Link from 'next/link'
import { FunctionComponent, useState } from 'react'

import { Avatar } from 'components/ui'

interface IRespondant {
  fromProfile: GetReferrals_opportunities_respondants_from_profile | null
  recommender: any
  recommendationMessage: string
  respondantId: number
}

const Respondant: FunctionComponent<IRespondant> = ({
  fromProfile,
  recommender,
  recommendationMessage,
  respondantId,
}) => {
  const [showMessage, setShowMessage] = useState(false)

  return (
    <div className="flex gap-2 items-center">
      <div className="px-4">
        <input type="checkbox" value={respondantId} name="respondant_profile" />
      </div>
      <div className="flex justify-between items-center p-2 mb-2 w-full rounded-md hover:cursor-pointer">
        <Link
          href={`/be/${fromProfile?.username}`}
          passHref
          className="max-w-[90%]"
        >
          <div className="flex gap-2 items-center hover:cursor-pointer">
            <div className="">
              <Avatar
                profile={fromProfile as GetHomeFeed_my_connections_posts_author}
                size={8}
                layout="fill"
                className="inline object-cover rounded-full"
                alt="Profile image"
              />
            </div>
            <div className="flex flex-col">
              <span className="leading-5 text-black truncate">
                {fromProfile?.first_name} {fromProfile?.last_name}
              </span>
              {recommender && (
                <>
                  <span className="text-xs truncate text-gray-mid">
                    recommended by{' '}
                    <span className="font-semibold text-gray-mid">
                      {recommender?.first_name} {recommender?.last_name}
                    </span>
                  </span>
                  <Transition
                    show={showMessage}
                    enter="transition-opacity duration-75"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <span className="text-xs italic text-gray-mid">
                      {recommendationMessage}
                    </span>
                  </Transition>
                </>
              )}
              {!recommender && (
                <span className="text-xs text-black truncate">
                  Submitted profile
                </span>
              )}
            </div>
          </div>
        </Link>
        {recommendationMessage && (
          <button onClick={() => setShowMessage((prev) => !prev)} type="button">
            {showMessage ? (
              <ChevronUpIcon className="flex-none w-4 h-4" />
            ) : (
              <ChevronDownIcon className="flex-none w-4 h-4" />
            )}
          </button>
        )}
      </div>
    </div>
  )
}

export default Respondant
