import { Dialog, Transition } from '@headlessui/react'
import { CheckCircleIcon, XIcon } from '@heroicons/react/outline'
import { Fragment, FunctionComponent, useState } from 'react'

interface ConfirmationMessageModal {
  message: string
  showConfirmation: boolean
  setShowConfirmation: (value: boolean) => void
  onClickFunction: () => void
}

const ConfirmationMessageModal: FunctionComponent<ConfirmationMessageModal> = ({
  message,
  showConfirmation,
  setShowConfirmation,
  onClickFunction,
}) => {
  return (
    <Transition appear show={showConfirmation} as={Fragment}>
      <Dialog
        as="div"
        onClose={() => setShowConfirmation(false)}
        className="relative z-50"
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="overflow-y-auto fixed inset-0">
          <div className="flex justify-center items-center p-4 min-h-full text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="overflow-hidden p-6 max-w-2xl text-left align-middle bg-white rounded-2xl shadow-xl transition-all transform">
                <Dialog.Title
                  as="div"
                  className="flex gap-2 justify-between items-center"
                >
                  <h3 className="self-start text-lg font-medium leading-6 text-gray-900">
                    <span>{message}</span>
                  </h3>
                  <button
                    className=""
                    onClick={() => setShowConfirmation(false)}
                  >
                    <XIcon className="text-gray-mid" width={24} height={24} />
                  </button>
                </Dialog.Title>
                <div className="flex gap-2 justify-center mt-4">
                  <button
                    className="py-1 px-3 w-20 text-sm text-white rounded-md bg-primary hover:bg-purple-mid"
                    onClick={onClickFunction}
                  >
                    Yes
                  </button>
                  <button
                    className="py-1 px-3 w-20 text-sm text-black bg-white rounded-md border border-gray-mid hover:text-purple-main hover:border-purple-mid"
                    onClick={() => setShowConfirmation(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default ConfirmationMessageModal
