import { TextInput } from '@/components/ui'
import { gql, useMutation, useQuery } from '@apollo/client'
import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useState,
} from 'react'

import useProfile from 'components/hooks/useProfile'

interface IRecommendationSelect {
  recommendationInfo: {}
  setRecommendationInfo: ({}) => void
}

const RecomendationOutsideBelongly: FunctionComponent<
  IRecommendationSelect
> = ({ recommendationInfo, setRecommendationInfo }) => {
  const { profile: ownerProfile } = useProfile()

  return (
    <div className="flex flex-col gap-3 p-1">
      <TextInput
        background="white"
        type="text"
        placeholder="First Name"
        onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
          setRecommendationInfo({
            ...recommendationInfo,
            firstName: target.value,
          })
        }
      />
      <TextInput
        background="white"
        type="text"
        className="w-full text-sm focus:outline-none text-gray-dark"
        placeholder="Last Name"
        onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
          setRecommendationInfo({
            ...recommendationInfo,
            lastName: target.value,
          })
        }
      />
      <TextInput
        background="white"
        type="email"
        className="w-full text-sm focus:outline-none text-gray-dark"
        placeholder="Email"
        onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
          setRecommendationInfo({
            ...recommendationInfo,
            email: target.value,
          })
        }
      />
    </div>
  )
}

export default RecomendationOutsideBelongly
