import { GetProfileByOwnerId_profiles } from 'operations/GetProfileByOwnerId'
import { GetReferrals_opportunities } from 'operations/GetReferrals'
import { GetRespondant } from 'operations/GetRespondant'

import { Q_GET_RESPONDANT } from 'lib/queries/opportunities'

import { useLazyQuery, useQuery } from '@apollo/client'
import { FunctionComponent, SetStateAction } from 'react'

import RecommendColleague from './recommend_colleague/recommend_colleague'
import RecommendationResponse from './recommendation_response'
import { ResponseState } from './referral'
import SubmitProfile from './submit_profile/submit_profile'

interface IReferralOptions {
  ownerProfile: GetProfileByOwnerId_profiles
  referralData: GetReferrals_opportunities
}

const ReferralOptions: FunctionComponent<IReferralOptions> = ({
  ownerProfile,
  referralData,
}) => {
  const [getRespondant, { data: respondantData }] = useLazyQuery<GetRespondant>(
    Q_GET_RESPONDANT,
    {},
  )
  const onClick = () => {
    getRespondant({
      variables: {
        fromProfileId: ownerProfile?.id,
        opportunityId: referralData.id,
      },
    })
  }

  const recommendationInfo = referralData.respondants.find((resp) => {
    return (
      resp.from_profile_id === ownerProfile.id &&
      resp.response_state !== ResponseState.DECLINED &&
      resp.response_state !== null
    )
  })

  const isProfileSubmitted =
    respondantData?.opportunity_respondants[0]?.response_type ==
    'SUBMIT_PROFILE'
  const buttonStyle = 'p-2 max-h-10 font-bold text-white rounded-lg w-fit'

  return (
    <div className="flex gap-2 justify-end mt-2" onClick={onClick}>
      <section className="mt-2">
        <RecommendColleague
          referralId={referralData.id}
          referralTitle={referralData.title}
          isRecommendColleagueOpen={false}
          setIsRecommendColleague={function (
            value: SetStateAction<boolean>,
          ): void {
            throw new Error('Function not implemented.')
          }}
        />
      </section>

      <section className="flex place-content-end mt-2">
        {!recommendationInfo && (
          <SubmitProfile
            isProfileSubmited={isProfileSubmitted}
            className={buttonStyle}
            referralData={referralData}
            bgColor={
              isProfileSubmitted
                ? 'bg-gray-mid hover:bg-gray-dark'
                : 'bg-green hover:bg-green-light'
            }
          >
            {isProfileSubmitted
              ? 'Withdraw My Profile'
              : 'I can take this referral'}
          </SubmitProfile>
        )}
        {recommendationInfo && (
          <RecommendationResponse recommendationInfo={recommendationInfo} />
        )}
      </section>
    </div>
  )
}

export default ReferralOptions
