import clsx from 'clsx'
import { merge } from 'ramda'
import { FunctionComponent, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

interface IThreeCol {
  left?: ReactNode
  right?: ReactNode
  fullScreen?: boolean
  hero?: ReactNode
  heroPosition?: 'right' | 'left'
  gap?: number
  leftSticky?: boolean
  rightSticky?: boolean
  className?: string
}

const ThreeCol: FunctionComponent<IThreeCol> = ({
  children,
  left,
  right,
  fullScreen = false,
  hero,
  heroPosition = 'left',
  leftSticky = false,
  rightSticky = false,
  gap = 12,
  className,
}) => {
  const isMissingSomeColumn = !left || !right
  const isMissingAllColumns = !left && !right
  const isMissingLeft = !left
  const isMissingRight = !right

  return (
    <div
      className={clsx(
        'grid grid-cols-5 md:grid-cols-7 lg:grid-cols-9 w-full bg-background',
        `gap-${gap}`,
        fullScreen ? 'h-[calc(100vh-4rem)]' : 'pb-52',
        className,
      )}
    >
      {heroPosition === 'left' ? (
        <>
          <div
            className={clsx(
              'grid',
              `gap-${gap}`,
              isMissingLeft &&
                !isMissingRight &&
                'col-span-5 lg:col-span-7 lg:grid-cols-7',
              isMissingSomeColumn
                ? 'col-span-9 grid-cols-9'
                : 'col-span-5 lg:col-span-7 lg:grid-cols-7',
            )}
          >
            {hero && (
              <div
                className={clsx(
                  'block sitcky top-28 h-fit',
                  isMissingSomeColumn
                    ? 'col-span-9 lg:col-span-7'
                    : 'col-span-9 lg:col-span-7',
                )}
              >
                {hero}
              </div>
            )}
            {left && (
              <div
                className={clsx(
                  'hidden lg:block col-span-2',
                  leftSticky && 'sticky top-28',
                  fullScreen ? 'h-full' : 'h-fit',
                )}
              >
                {left}
              </div>
            )}
            {children && (
              <div
                className={twMerge(
                  'block',
                  isMissingSomeColumn
                    ? 'col-span-9 lg:col-span-7'
                    : 'col-span-9 lg:col-span-5',
                  isMissingAllColumns && 'lg:col-span-9',
                )}
              >
                {children}
              </div>
            )}
          </div>
          {right && (
            <div
              className={clsx(
                'hidden md:block col-span-2',
                rightSticky && 'sticky top-28',
                fullScreen ? 'h-full' : 'h-fit',
              )}
            >
              {right}
            </div>
          )}
        </>
      ) : (
        <>
          {left && (
            <div
              className={clsx(
                'hidden lg:block col-span-2',
                leftSticky && 'sticky top-28',
                fullScreen ? 'h-full' : 'h-fit',
              )}
            >
              {left}
            </div>
          )}
          <div
            className={clsx(
              'grid',
              `gap-${gap}`,
              isMissingSomeColumn
                ? 'col-span-9 grid-cols-9'
                : 'col-span-7 grid-cols-7',
            )}
          >
            {hero && (
              <div
                className={clsx(
                  'block sitcky top-28 h-fit',
                  isMissingSomeColumn
                    ? 'col-span-9 grid-cols-9'
                    : 'col-span-7 grid-cols-7',
                )}
              >
                {hero}
              </div>
            )}
            {children && (
              <div
                className={clsx(
                  'block',
                  isMissingSomeColumn ? 'col-span-7' : 'col-span-5',
                )}
              >
                {children}
              </div>
            )}
            {right && (
              <div
                className={clsx(
                  'hidden lg:block col-span-2',
                  rightSticky && 'sticky top-28',
                  fullScreen ? 'h-full' : 'h-fit',
                )}
              >
                {right}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default ThreeCol
