import { Disclosure, Transition } from '@headlessui/react'
import { TrashIcon } from '@heroicons/react/outline'
import { FunctionComponent } from 'react'

import { Avatar } from 'components/ui'

import { SelectedInvitee } from './recommend_colleague'

interface IRecommendationSelected {
  invitee: SelectedInvitee | null
  removeInvitee: () => void
  addMessage: (message: string) => void
}

const RecommendationSelected: FunctionComponent<IRecommendationSelected> = ({
  invitee,
  removeInvitee,
  addMessage,
}) => {
  return (
    <Disclosure>
      <div className="flex justify-between items-center py-2 px-4 mb-2 w-full text-black bg-white rounded-md">
        <span className="flex gap-4 items-center w-full">
          <button onClick={removeInvitee} className="flex items-end">
            <TrashIcon className="w-5 h-5 stroke-red" />
          </button>
          <p className="flex gap-2 items-center font-semibold">
            <div>
              <Avatar
                size={8}
                profile={invitee || undefined}
                layout="fill"
                className="inline object-cover rounded-full"
                alt="Profile image"
              />
            </div>
            {invitee?.first_name} {invitee?.last_name}
          </p>
          <Disclosure.Button className="py-1 px-2 ml-auto text-xs rounded-md border active:text-white active:border-white border-gray-mid active:bg-primary">
            <button>Add a note</button>
          </Disclosure.Button>
        </span>
      </div>
      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
        className="relative -top-4 w-full"
      >
        <Disclosure.Panel className="py-2 px-4 mt-2 mb-2 w-full rounded-b-md">
          <h3>Write a public comment...</h3>
          <textarea
            onChange={(event) => addMessage(event.target.value)}
            placeholder="ex: Lisa specializes in this area and I've heard great feedback from patients who see her."
            rows={3}
            className="p-2 mt-2 w-full rounded-md border border-gray-mid"
          />
        </Disclosure.Panel>
      </Transition>
    </Disclosure>
  )
}

export default RecommendationSelected
