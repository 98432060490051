import { GetPostComments_post_comments } from 'operations/GetPostComments'
import { GetReferralComments_opportunity_comments } from 'operations/GetReferralComments'

import {
  M_COMMENT_LIKE,
  M_COMMENT_UNLIKE,
  M_DELETE_COMMENT,
} from 'lib/mutations/post_comment'

import { useMutation } from '@apollo/client'
import { PencilAltIcon, TrashIcon } from '@heroicons/react/outline'
import Link from 'next/link'
import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { getDistanceLabel } from 'utils'

import CommentIcon from 'components/common/comment_icon'
import CurrentJob from 'components/common/current_job'
import { PermissionWrapper } from 'components/common/hocs'
import MentionsDetector from 'components/common/hocs/MentionsDectector'
import LikeIcon from 'components/common/like_icon'
import useProfile from 'components/hooks/useProfile'
import useSubmitComment from 'components/hooks/useSubmitComment'
import {
  Avatar,
  ConfirmationModal,
  Menu,
  MobileSwitch,
  Modal,
  PostButton,
  Text,
} from 'components/ui'

import AuthorProfile from '../author_profile'
import ReplyingBox from '../replying_box'
import EditComment from './edit_comment'

interface CommentProps {
  comment:
    | GetPostComments_post_comments
    | GetReferralComments_opportunity_comments
  toPostId: number
  isReply?: boolean
  category: 'post' | 'opportunity'
  postAuthorId?: number
  isAnonymousPost?: boolean
  isOnFeed?: boolean
  isChild?: boolean
}

const Comment = ({
  comment,
  toPostId,
  isReply = false,
  category,
  postAuthorId,
  isAnonymousPost = false,
  isOnFeed = false,
}: CommentProps) => {
  const { id: myId } = useProfile()

  const { onOpenReply, isReplying, closeReply, onPost, replyingToAuthorId } =
    useSubmitComment({
      postType: category,
    })

  const isPost = category === 'post'

  const isAnonymous =
    (comment as GetPostComments_post_comments)?.is_anonymous ?? false

  const likedComments =
    'liked_comments' in comment ? comment.liked_comments : []

  const likesCount =
    'liked_comments_aggregate' in comment
      ? comment.liked_comments_aggregate.aggregate?.count || 0
      : 0

  const isLikedByMe = !!likedComments.find(
    (like: { from_profile_id: number | undefined }) =>
      like.from_profile_id === myId,
  )

  const [showLikeList, setShowLikeList] = useState<boolean>(false)
  const [isCommentLiked, setIsCommentLiked] = useState<boolean>(isLikedByMe)
  const [likeCount, setLikeCount] = useState<number>(likesCount)
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [showDeletionModal, setShowDeletionModal] = useState<boolean>(false)

  useEffect(() => {
    setIsCommentLiked(isLikedByMe)
  }, [isLikedByMe])

  const [likeCommentMutation, { loading: likeLoadingMutation }] =
    useMutation(M_COMMENT_LIKE)
  const [unlikeCommentMutation, { loading: unlikeLoadingMutation }] =
    useMutation(M_COMMENT_UNLIKE)

  const [deleteComment] = useMutation(M_DELETE_COMMENT, {
    onCompleted: () => setShowDeletionModal(false),
    refetchQueries: [
      'GetPostComments',
      'GetReferrals',
      'GetReferralById',
      'GetHomeFeed',
      'GetPost',
    ],
  })

  const onLike = () => {
    if (isCommentLiked) {
      setIsCommentLiked(false)
      setLikeCount(likeCount > 1 ? likeCount - 1 : 0)
      unlikeCommentMutation({
        variables: {
          commentId: comment.id,
          fromProfileId: myId,
        },
      })
    } else {
      setIsCommentLiked(true)
      setLikeCount(likeCount + 1)
      likeCommentMutation({
        variables: {
          commentId: comment.id,
          fromProfileId: myId,
        },
      })
    }
  }

  const handleOnPost = (values: {
    comment: string
    postId: number
    isAnonymous: boolean
  }) => {
    const isReplyToAnonComment =
      isReply && isAnonymousPost && replyingToAuthorId === postAuthorId
    onPost({
      comment: values.comment,
      postId: values.postId,
      isAnonymous: values.isAnonymous,
      isReplyToAnonComment,
    })
  }

  const author = comment?.author || undefined

  const isMine = comment.author?.id === myId

  const isFromPostAuthor = postAuthorId === author?.id

  return (
    <>
      <div
        className={twMerge(
          'flex flex-col justify-between items-start gap-3',
          isReply && 'ml-11',
        )}
      >
        <div className="flex gap-7 w-full">
          <div>
            {!author || isAnonymous ? (
              <Avatar
                profile={isAnonymous ? undefined : author}
                layout="fill"
                className="object-cover rounded-full"
                alt="Profile image"
                size={12}
              />
            ) : (
              <Link href={`/be/${author.username}`} passHref prefetch={false}>
                <Avatar
                  profile={author}
                  layout="fill"
                  className="object-cover rounded-full"
                  alt="Profile image"
                  size={12}
                />
              </Link>
            )}
          </div>
          <div className="flex flex-col gap-2 w-full">
            <div className="flex flex-col gap-2 p-4 w-full rounded bg-background">
              <div className="flex flex-col gap-1 w-full">
                <div className="flex justify-between">
                  <div className="flex gap-2 items-center text-sm font-bold capitalize">
                    {isAnonymous ? (
                      <Text
                        variant="small"
                        className="text-black"
                        weight="semibold"
                      >
                        Anonymous Member
                      </Text>
                    ) : (
                      <Link
                        href={`/be/${author?.username}`}
                        passHref
                        prefetch={false}
                        legacyBehavior
                      >
                        <Text
                          variant="small"
                          className="text-black"
                          weight="semibold"
                        >
                          {`${author?.first_name} ${author?.last_name}`.trim()}
                        </Text>
                      </Link>
                    )}
                    {isFromPostAuthor && (
                      <div className="px-4 rounded bg-secondary">
                        <MobileSwitch
                          regularView={
                            <Text variant="extra-small" color="white">
                              Author
                            </Text>
                          }
                          mobileView={
                            <Text variant="extra-small" color="white">
                              {isReply ? 'A' : 'Author'}
                            </Text>
                          }
                        />
                      </div>
                    )}
                  </div>
                  <div className="flex relative gap-4 items-center">
                    <MobileSwitch
                      regularView={
                        <Text variant="extra-small">
                          {getDistanceLabel(comment?.created_at)}
                        </Text>
                      }
                    />
                    {isMine && (
                      <Menu
                        direction="left"
                        options={[
                          {
                            label: 'Edit',
                            icon: (active: boolean) => (
                              <PencilAltIcon
                                className={twMerge(
                                  active && 'stroke-white',
                                  'h-4 w-4',
                                )}
                              />
                            ),
                            onClick: () => setIsEditing(true),
                          },
                          {
                            label: 'Delete',
                            icon: (active: boolean) => (
                              <TrashIcon
                                className={twMerge(
                                  active && 'stroke-white',
                                  'h-4 w-4',
                                )}
                              />
                            ),
                            onClick: () => setShowDeletionModal(true),
                          },
                        ]}
                      />
                    )}
                  </div>
                </div>
                {(author?.current_job_new || author?.experiences.lenght > 0) &&
                  !isAnonymous && (
                    <div
                      className={twMerge(
                        'md:max-w-sm',
                        isReply ? 'max-w-[9rem]' : 'max-w-[12rem]',
                      )}
                    >
                      <CurrentJob
                        className="text-xs truncate max-w-fit"
                        currentJob={`${author?.current_job_new}${author?.current_job_new}`}
                        experiences={author?.experiences}
                      />
                    </div>
                  )}
              </div>
              <div className="flex flex-col gap-4 w-full text-xs whitespace-pre-line md:text-[15px]">
                <MentionsDetector content={comment.content} textSize="sm" />
              </div>
            </div>
            <div className="flex justify-between items-center w-full">
              {likeCount > 0 && isPost ? (
                <div className="text-xs font-bold achieved text-gray-mid">
                  <Text
                    onClick={() => setShowLikeList(true)}
                    variant="extra-small"
                    className="cursor-pointer"
                    color="primary"
                  >
                    {likeCount.toLocaleString('en-US')} Like
                    {likeCount != 1 && 's'}
                  </Text>
                </div>
              ) : (
                <div />
              )}
              <div className="flex gap-2 text-black">
                {isPost && (
                  <PermissionWrapper action="LikeComment" behavior="Disable">
                    <PostButton
                      icon={<LikeIcon liked={isCommentLiked} />}
                      label="Like"
                      onClick={onLike}
                      disabled={likeLoadingMutation || unlikeLoadingMutation}
                    />
                  </PermissionWrapper>
                )}
                <PermissionWrapper action="ReplyComment" behavior="Disable">
                  <PostButton
                    onClick={() => {
                      onOpenReply({
                        replyingToId: isReply
                          ? comment.parent_id || -1
                          : comment.id,
                        authorId: comment?.author?.id,
                        replyingToReply: isReply,
                        authorName: comment.author?.first_name || '',
                      })
                    }}
                    icon={<CommentIcon className="w-4 h-4 text-primary" />}
                    label="Reply"
                  />
                </PermissionWrapper>
              </div>
            </div>
          </div>
        </div>
        {isReplying && !isOnFeed && (
          <div className="hidden w-full md:flex">
            <ReplyingBox
              category={category}
              onPost={handleOnPost}
              isReply={true}
              toPostId={toPostId}
              closeReplyingBox={closeReply}
              isAnonymous={isAnonymousPost}
              postAuthorId={postAuthorId!}
              postAuthor={comment.author}
            />
          </div>
        )}
      </div>
      {isReplying && !isOnFeed && (
        <div className="flex w-full md:hidden">
          <ReplyingBox
            category={category}
            onPost={handleOnPost}
            isReply={true}
            toPostId={toPostId}
            closeReplyingBox={closeReply}
            isAnonymous={isAnonymousPost}
            postAuthorId={postAuthorId!}
            postAuthor={comment.author}
          />
        </div>
      )}
      <Modal
        isOpen={showLikeList}
        onClose={() => setShowLikeList(false)}
        title="Liked by:"
      >
        {likedComments?.map((reply) => (
          <AuthorProfile
            key={reply.created_at}
            isAnonymous={reply.from_profile.id === myId && isAnonymousPost}
            author={reply.from_profile}
          />
        ))}
      </Modal>
      <EditComment
        isEditing={isEditing}
        closeDialog={() => {
          setIsEditing(false)
        }}
        isAnonymous={isAnonymous}
        comment={comment}
      />
      <ConfirmationModal
        message="Are you sure you want to delete this comment? This action is irreversible."
        isOpen={showDeletionModal}
        onClose={() => setShowDeletionModal(false)}
        onConfirm={() => deleteComment({ variables: { id: comment.id } })}
        positiveMessage="Delete"
      />
    </>
  )
}

export default Comment
